import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import sessions from './sessions';
import entities from './entities';
import general from './general';
import authentication from './authentication';

const createRootReducer = history => combineReducers({ 
  router: connectRouter(history),
  entities,
  general,
  sessions,
  authentication
});

export default createRootReducer;
