import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Loading from '../../shared/Loading';
import ProgressBars from '../../shared/ProgressBars';
import Support from '../../shared/Support';
import useRouteStatus from '../../../hooks/useRouteStatus';
import useSession from '../../../hooks/useSession';
import useSessionEntity from '../../../hooks/useSessionEntity';
import { error } from '../../../actions/general';
import { loading } from '../../../actions/sessions';
import { updateParent } from '../../../lib/utils/modal';

export default function OrderConfirmation({ location }) {
  const [session, sessionActions] = useSession();
  const dispatch = useDispatch();
  const cart = useSessionEntity('shopping_cart');
  const company = useSessionEntity('company');

  useRouteStatus(location);

  const canPlaceOrder = () => {
    if (cart?.return_url === '') return false;
    if (company?.underwriting_status !== 'approved') return false;

    return true;
  };

  useEffect(() => {
    if (canPlaceOrder()) {
      if (session.mode === 'modal') {
        sessionActions.finish().then(() => {
          updateParent('complete', cart ? cart.return_url : '');
          // Handle modal routes loaded in a standalone browser window:
          // updateParent should cause the parent to do a full redirect immediately
          // wait 10 seconds, if we're still here, hard redirect to complete order
          setTimeout(function () {
            window.location.href = cart?.return_url;
          }, 10 * 1000);
        });
      } else {
        sessionActions
          .finish()
          .then(() => (window.location.href = cart?.return_url));
      }
    } else {
      dispatch(
        error(
          'unable to complete order based on canPlaceOrder() check for cart:' +
            cart?.id,
        ),
      );
    }
  }, []);

  if (!canPlaceOrder()) {
    dispatch(loading(false));

    return (
      <div role="main"  className="mx-6 sm:mx-auto">
        <div className="my-4 flex items-center">
          <span className="fa-layers fa-fw text-5xl">
            <FontAwesomeIcon
              icon="square"
              className="text-background-error !mx-0"
            />
            <FontAwesomeIcon
              icon={['far', 'triangle-exclamation']}
              className="text-error text-3xl !mx-1.5"
            />
          </span>
          <div className="text-xl sm:text-4xl font-extrabold">We're sorry!</div>
        </div>
        <div className="tracking-wide mb-6 font-light text-lg">
          Something isn't right with your order. Please contact support for
          further guidance.
        </div>
        <Support />
      </div>
    );
  }

  return (
    <div role="main"  className="mx-6 sm:mx-auto">
      <ProgressBars />
      <div className="my-4 flex items-center">
        <span className="fa-layers fa-fw text-5xl">
          <FontAwesomeIcon
            icon="square"
            className="text-secondary-blue !mx-0"
          />
          <FontAwesomeIcon
            icon={["far", "thumbs-up"]}
            className="text-primary-blue text-3xl !mx-1.5"
          />
        </span>
        <div className="text-xl sm:text-4xl font-extrabold">
          Congratulations!
        </div>
      </div>
      <div className="tracking-wide mb-6 font-light text-lg">
        Your order is being processed.
      </div>
      <Loading className="w-14 h-14" containerClass="mt-4 mb-10" />
      <Support />
    </div>
  );
}
