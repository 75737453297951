import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Alert from '../../shared/Alert';
import ColumnInput from '../../shared/inputs/ColumnInput';
import Error from '../../shared/Error';
import PrimaryButton from '../../shared/buttons/PrimaryButton';
import ProgressBars from '../../shared/ProgressBars';
import StickyInput from '../../shared/inputs/StickyInput';
import Support from '../../shared/Support';
import useRouteStatus from '../../../hooks/useRouteStatus';
import useSessionEntity from '../../../hooks/useSessionEntity';
import { asyncActions } from '../../../actions/general';
import { emailRegex, checkEmail } from '../../../lib/constants/SiteVariables';
import { loading, notifyOwner } from '../../../actions/sessions';

export default function NotifyOwner({ location }) {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.sessions.get('loading'));
  const asyncErrors = useSelector((state) => state.general.get('errors'));
  const application = useSessionEntity('application');
  const [notified, setNotified] = useState(false);
  const [purchaserError, setPurchaserError] = useState(false);
  const [owner, setOwner] = useState({
    first_name: '',
    last_name: '',
    email: '',
  });
  const purchaser = {
    purchaser_first_name: '',
    purchaser_last_name: '',
    purchaser_email: '',
    purchaser_phone: '',
  };

  const save = (values) => {
    dispatch(loading(true));
    setPurchaserError(false)
    const updatedOwner = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
    };
    const updatedPurchaser = {
      first_name: values.purchaser_first_name,
      last_name: values.purchaser_last_name,
      email: values.purchaser_email,
      phone: values.purchaser_phone,
    };

    const userMatch = values.email === values.purchaser_email;

    if (userMatch) {
      dispatch(loading(false));
      return setPurchaserError('Duplicate Email');
    }

    dispatch(asyncActions(notifyOwner(updatedOwner, updatedPurchaser)))
      .then((res) => setNotified(res?.owner_notified))
      .then((res) => {
        setOwner(updatedOwner);
        dispatch(loading(false));
      });
  };

  const purchaserErrorMsg = () => {
    if (purchaserError === 'Duplicate Email') {
      return (
        <div>
          If you are the owner of this business, please
          <Link
            to="/apply/ownership"
            className="underline mx-1 cursor-pointer font-bold"
          >
            go back
          </Link>
          and select the 'I am the owner' option.'
        </div>
      );
    } else if (purchaserError === 'Invalid purchaser phone number') {
      return 'Please enter a valid phone number and try again';
    } else {
      return 'Please contact Credit Key Support for further assistance';
    }
  };

  useEffect(() => {
    if (asyncErrors.count() > 0) {
      asyncErrors.find((e) => {
        if (e && e.includes('Purchaser user already exists')) {
          setPurchaserError('Purchaser user already exists');
        } else if (e && e.includes('Please enter a valid phone number')) {
          setPurchaserError('Invalid purchaser phone number');
        }
      });
    }
  }, [asyncErrors]);

  useRouteStatus(location);

  const ownerNotNotified = (
    <div role="main" className="mx-6 sm:mx-auto">
      <ProgressBars />
      <div className="my-4 flex items-center">
        <div className="text-xl sm:text-4xl font-extrabold">
          That owner already is a Credit Key customer.
        </div>
      </div>

      <div className="tracking-wide font-light sm:text-lg mb-2">
        Please contact Credit Key support for further assistance.
      </div>
      <div className="mt-6 sm:mt-12">
        <Support />
      </div>
    </div>
  );

  const ownerNotified = (
    <div role="main" className="mx-6 sm:mx-auto">
      <ProgressBars />
      <div className="my-4 flex items-center">
        <span className="fa-layers fa-fw text-5xl">
          <FontAwesomeIcon
            icon="square"
            className="text-secondary-blue !mx-0"
          />
          <FontAwesomeIcon
            icon={['far', 'thumbs-up']}
            className="text-primary-blue text-3xl !mx-1.5"
          />
        </span>
        <div className="text-xl sm:text-4xl font-extrabold">Success!</div>
      </div>
      <div className="tracking-wide font-light sm:text-lg mb-2">
        We've sent an email to {owner.first_name} {owner.last_name} for approval
        and order submission.
      </div>

      <div className="tracking-wide font-light sm:text-lg mb-2">
        Once {owner.first_name} completes the process, you will have the ability
        to make future purchases using Credit Key, without the need for
        additional approval.
      </div>
      <div className="mt-6 sm:mt-12">
        <Support />
      </div>
    </div>
  );

  if (!!owner.email && !purchaserError) {
    if (notified) return ownerNotified;
    if (!notified) return ownerNotNotified;
  }

  return (
    <div role="main" className="mx-6 sm:mx-auto mb-10">
      <ProgressBars />
      <div className="mt-4 mb-1 sm:mb-4">
        <div className="text-base sm:text-3xl font-extrabold">
          Owner Express Approval
        </div>
      </div>
      <div className="tracking-wide font-light text-sm mb-2">
        Credit Key needs to approve an owner of your business.
      </div>
      <div className="tracking-wide font-light text-sm mb-2">
        We'll need about 5 minutes of your business owner's time to approve this
        order, and get you approved to purchase for {application.company_name}.
      </div>
      <div className="tracking-wide font-light text-sm mb-2">
        Please provide the user details below for yourself and an owner of
        <span className="text-primary-blue mx-1">
          {application.company_name}:
        </span>
      </div>

      {purchaserError ? (
        <Alert
          containerClass="bg-background-error p-4 pt-3 w-full m-auto"
          icon={['far', 'triangle-exclamation']}
          alertText={purchaserError}
          alertSubtext={purchaserErrorMsg()}
        />
      ) : (
        <Error />
      )}

      <Formik
        initialValues={{ ...owner, ...purchaser }}
        validate={async (values) => {
          const errors = {};

          if (!values.first_name) errors.first_name = '* Required';
          if (!values.last_name) errors.last_name = '* Required';

          if (!values.email) {
            errors.email = '* Required';
          } else if (
            !emailRegex.test(values.email.toLowerCase()) ||
            !checkEmail(values.email.toLowerCase())
          ) {
            errors.email = '* Invalid email address';
          }

          if (!values.purchaser_first_name)
            errors.purchaser_first_name = '* Required';
          if (!values.purchaser_last_name)
            errors.purchaser_last_name = '* Required';

          if (!values.purchaser_email) {
            errors.purchaser_email = '* Required';
          } else if (
            !emailRegex.test(values.purchaser_email.toLowerCase()) ||
            !checkEmail(values.purchaser_email.toLowerCase())
          ) {
            errors.purchaser_email = '* Invalid email address';
          }
          if (!values.purchaser_phone) {
            errors.purchaser_phone = '* Required';
          } else if (values.purchaser_phone.replace(/[^\d]/g, '').length < 10) {
            errors.purchaser_phone = '* Please enter a valid phone number';
          }

          return errors;
        }}
        onSubmit={save}
      >
        {({ errors, values, touched }) => (
          <Form className="mt-4">
            <div className="divide-y my-2">
              <div className="pb-2">
                <div className="font-semibold text-sm my-2">Owner Details</div>
                <ColumnInput
                  error={errors.first_name}
                  touched={touched.first_name}
                >
                  <StickyInput
                    id="first_name"
                    data-testid="first_name"
                    placeholder="First Name"
                    values={values}
                  />
                </ColumnInput>

                <ColumnInput
                  error={errors.last_name}
                  touched={touched.last_name}
                >
                  <StickyInput
                    id="last_name"
                    data-testid="last_name"
                    placeholder="Last Name"
                    values={values}
                  />
                </ColumnInput>

                <ColumnInput error={errors.email} touched={touched.email}>
                  <StickyInput
                    id="email"
                    data-testid="email"
                    type="email"
                    placeholder="Email"
                    values={values}
                  />
                </ColumnInput>
              </div>
              <div>
                <div className="font-semibold text-sm my-2">
                  Purchaser Details
                </div>
                <ColumnInput
                  error={errors.purchaser_first_name}
                  touched={touched.purchaser_first_name}
                >
                  <StickyInput
                    id="purchaser_first_name"
                    data-testid="purchaser_first_name"
                    placeholder="First Name"
                    values={values}
                  />
                </ColumnInput>

                <ColumnInput
                  error={errors.purchaser_last_name}
                  touched={touched.purchaser_last_name}
                >
                  <StickyInput
                    id="purchaser_last_name"
                    data-testid="purchaser_last_name"
                    placeholder="Last Name"
                    values={values}
                  />
                </ColumnInput>

                <ColumnInput
                  error={errors.purchaser_email}
                  touched={touched.purchaser_email}
                >
                  <StickyInput
                    id="purchaser_email"
                    data-testid="purchaser_email"
                    type="email"
                    placeholder="Email"
                    values={values}
                  />
                </ColumnInput>

                <ColumnInput
                  error={errors.purchaser_phone}
                  touched={touched.purchaser_phone}
                >
                  <StickyInput
                    id="purchaser_phone"
                    data-testid="purchaser_phone"
                    type="tel"
                    mask="(999) 999-9999"
                    placeholder="Mobile Phone"
                    values={values}
                  />
                </ColumnInput>
              </div>
            </div>
            <PrimaryButton
              buttonText="Send Approval Link to Owner"
              buttonColor="bg-primary-blue"
              hoverClass="hover:bg-primary-blue-hover"
              disabled={isLoading}
              isLoading={isLoading}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}
