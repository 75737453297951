import React from 'react';

export default function Fraud({ user }) {
  return (
    <div>
      <div className="font-semibold text-sm sm:text-lg tracking-wide mb-4">
        Hi {user?.first_name},
      </div>

      <div className="font-light text-sm sm:text-lg tracking-wider leading-5 mb-3">
        Thank you for your interest with Credit Key. For your security, your
        application is pending.
      </div>

      <div className="font-light text-sm sm:text-lg tracking-wider leading-5 mb-3">
        Our team needs to reach out and verify some information over the phone.
        For security purposes, the call needs to come from Credit Key to you.
      </div>

      <div className="font-light text-sm sm:text-lg tracking-wider leading-5 mb-3">
        We will call you as soon as possible, and apologize in advance for any
        inconvenience. If you want to specify a time/day for us to call you,
        please feel free to reach out to Credit Key Support in the chat window
        below, or by <a href="mailto: support@creditkey.com">email</a>.
      </div>

      <div className="font-light text-sm sm:text-lg tracking-wider leading-5 mb-3">
        If we’re unable to reach you within 10 business days, we’ll consider
        your credit application withdrawn.
      </div>
    </div>
  );
}
