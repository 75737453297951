import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ResumeCart from './purchase/ResumeCart';
import Terms from './purchase/Terms';
import PhoneVerification from './purchase/PhoneVerification';
import PinVerification from './purchase/PinVerification';
import AddPaymentMethod from './purchase/AddPaymentMethod';
import Confirm from './purchase/Confirm';
import useSessionEntity from '../../hooks/useSessionEntity';
import Loading from '../shared/Loading';

export default function Purchase({ match }) {
  const publicKey = useSelector(state => state.sessions.get('public_key'));
  const isLoading = useSelector(state => state.sessions.get('loading'));
  const cart = useSessionEntity('shopping_cart');

  if (!publicKey && !isLoading) {
    return (<Redirect to="/invalid" />);
  }

  if (!cart) {
    return (<Loading className='w-14 h-14' />);
  }

  return (
    <Switch>
      <Route path={`${match.path}/resume_cart`} component={ResumeCart} />
      <Route path={`${match.path}/terms`} component={Terms} />
      <Route path={`${match.path}/phone`} component={PhoneVerification} />
      <Route path={`${match.path}/verify/:type/:contact`} component={PinVerification} />
      <Route path={`${match.path}/require_bank_credentials`} component={AddPaymentMethod} />
      <Route path={`${match.path}/confirm`} component={Confirm} />
    </Switch>
  );
}
