import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ModalWrapper({ children, isModalActive, toggleModal }) {
  return (
    <div role={ModalWrapper.role} className="hidden">
      <Transition.Root show={isModalActive} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-100 inset-0 font-manrope"
          onClose={toggleModal}
        >
          <div className="flex items-end justify-center sm:min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block align-bottom bg-white rounded-lg pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="absolute top-6 right-6">
                  <button
                    type="button"
                    className="text-tertiary-gray h-4 w-4 flex items-center justify-center hover:text-secondary-gray"
                    onClick={toggleModal}
                  >
                    <span className="sr-only">Close</span>
                    <FontAwesomeIcon
                      icon="times"
                      className="text-xs xs:text-base"
                    />
                  </button>
                </div>
                <div className="px-2 xxs:px-4 sm:px-6 sm:py-3 overflow-y-scroll max-h-modal">
                  {children}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

ModalWrapper.role = 'modal-wrapper-component';
