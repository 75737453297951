import React from 'react';

export default function Default() {
  return (
    <div>
      <p className="font-light sm:text-lg tracking-wide mb-6">
        It looks like we'll need a bit more information to complete your order.
      </p>

      <p className="font-semibold text-lg tracking-wide mb-6">
        Check your email for next steps.
      </p>
    </div>
  );
}
