import { receiveEntity } from './entities';

const type = 'shopping_carts';

const path = resource => {
  if (!resource) resource = '';
  return { resource: 'api/' + type + '/' + resource };
}

export const getShoppingCart = id => {
  return (dispatch, getState, api) => {
    return api.get(path(id))
      .then(res => dispatch(receiveEntity(res)));
  }
}

export const getLoanAgreement = cart => {
  return (dispatch, getState, api) => {
    return api.get(path(cart.id + '/loan_agreement'));
  }
}

export const getOrderMessages = cart => {
  return (dispatch, getState, api) => {
    return api.get(path(cart.id + '/order_messages'));
  }
}

export const confirmShoppingCart = (cart, agree, rechargeAgree) => {
  return (dispatch, getState, api) => {
    return api.post(path(cart.id + '/confirm'), { agree: agree, recharge_agree: rechargeAgree })
      .then(res => dispatch(receiveEntity(res)));
  }
}

export const confirmInstoreShoppingCart = (cart) => {
  return (dispatch, getState, api) => {
    return api.post(path(cart.id + '/instore_order_confirm'))
      .then(res => dispatch(receiveEntity(res)));
  }
}

export const confirmStandaloneShoppingCart = (cart) => {
  return (dispatch, getState, api) => {
    return api.post(path(cart.id + '/standalone_order_confirm'))
      .then(res => dispatch(receiveEntity(res)));
  }
}

export const updateShoppingCart = cart => {
  return (dispatch, getState, api) => {
    return api.edit(path(cart.id), cart)
      .then(res => dispatch(receiveEntity(res)));
  }
}

export const verifyShoppingCart = cart => {
  return (dispatch, getState, api) => {
    return api.get(path(cart.id + '/verify'), cart)
      .then(res => dispatch(receiveEntity(res)));
  }
}
