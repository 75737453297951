import { receiveEntity, updateEntity } from './entities';

const type = 'applications';

const path = (resource) => {
  if (!resource) resource = '';
  return { resource: 'api/' + type + '/' + resource };
};

export const getApplication = (id) => {
  return (dispatch, getState, api) => {
    return api.get(path(id)).then((res) => dispatch(receiveEntity(res)));
  };
};

export const updateApplication = (application) => {
  return (dispatch, getState, api) => {
    return api
      .edit(path(application.id), application)
      .then((res) => dispatch(receiveEntity(res)));
  };
};

export const submitApplication = (application) => {
  return (dispatch, getState, api) => {
    return api
      .edit(path(`${application.id}/submit_application`), application)
      .then((res) => dispatch(receiveEntity(res)));
  };
};

export const submitSoftApplication = (application) => {
  return (dispatch, getState, api) => {
    return api
      .edit(path(`${application.id}/soft_underwrite`), application)
      .then((res) => dispatch(receiveEntity(res)));
  };
};
