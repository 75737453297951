import { useCallback, useEffect, useState } from 'react';

function useModal(availableScreens = [], initialModalState = undefined) {
  const [isModalActive, setIsModalActive] = useState(false);
  const [modalState, setModalState] = useState(initialModalState);
  const [activeScreen, setActiveScreen] = useState();
  const [screenIndex, setScreenIndex] = useState(0);

  const toggleModal = useCallback(() => setIsModalActive(isModalActive => !isModalActive), []);

  const nextScreen = () => {
    const newIndex = screenIndex + 1;

    if (newIndex <= availableScreens.length) {
      setScreenIndex(newIndex);
    }
  };

  const previousScreen = () => {
    const newIndex = screenIndex - 1;

    if (newIndex < availableScreens.length) {
      setScreenIndex(newIndex);
    }
  };

  const resetScreen = () => setScreenIndex(0);

  const resetModalState = () => setModalState(initialModalState);

  useEffect(() => {
    setActiveScreen(availableScreens[screenIndex]);
  }, [screenIndex, availableScreens]);

  return {
    activeScreen,
    modalState,
    nextScreen,
    previousScreen,
    resetScreen,
    screenIndex,
    setActiveScreen,
    setModalState,
    resetModalState,
    setScreenIndex,
    isModalActive,
    setIsModalActive,
    toggleModal,
  };
};

export default useModal;
