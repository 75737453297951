import moment from 'moment';
import {
  directionalAbbreviations,
  streetTypes,
} from '../constants/SiteVariables';
/**
 * General wrapper to cache API validation calls.
 */
export const cacheAsyncResponse = (asyncValidate) => {
  let _valid = false;
  let _args = '';

  return async (...args) => {
    if (args.toString() !== _args) {
      const response = await asyncValidate(...args);
      _args = args.toString();
      _valid = response;

      return response;
    }

    return _valid;
  };
};

export const addressValidation = (businessAddress, personalAddress) => {
  let fullMatch = false;
  const fields = ['address1', 'city', 'state', 'zip'];

  const removeStreetSuffix = (street) => {
    const parts = street.split(' ');
    const matchIndex = parts.findIndex((part) =>
      streetTypes.includes(part.toLowerCase()),
    );
    const result =
      matchIndex !== -1 ? parts.slice(0, matchIndex).join(' ') : street;

    return result;
  };

  const cleaned = (address) => {
    let cleanedStreetAddress;
    const directionRegEx = new RegExp('\\b(' + directionalAbbreviations.join('|') + ')\\b','gi');

    cleanedStreetAddress = address.replace(/[^a-z0-9]/gi, ' ').toLowerCase();
    cleanedStreetAddress = cleanedStreetAddress.replace(directionRegEx, '');

    if (isNaN(cleanedStreetAddress.charAt(0))) {
      cleanedStreetAddress = cleanedStreetAddress.substring(1);
    }

    cleanedStreetAddress = removeStreetSuffix(cleanedStreetAddress);

    cleanedStreetAddress = cleanedStreetAddress
      .split(' ')
      .filter((str) => str !== '')
      .join(' ');

    return cleanedStreetAddress;
  };

  for (let field of fields) {
    if (field === 'address1') {
      if (
        cleaned(businessAddress?.address1) ===
        cleaned(personalAddress?.address1)
      ) {
        fullMatch = true;
      }
    } else {
      if (
        personalAddress[field].toLowerCase() !==
        businessAddress[field].toLowerCase()
      ) {
        fullMatch = false;
      }
    }
  }

  return fullMatch;
};

export const validateEstablishedDate = (dateString) => {
  const date = moment(dateString, 'MM/YYYY');
  return moment(date).isValid();
};
