import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Loading from '../../../shared/Loading';
import OutlineButton from '../../../shared/buttons/OutlineButton';
import payOnline from '../../../../lib/images/navy-online-payments.svg';
import PrimaryButton from '../../../shared/buttons/PrimaryButton';
import useSessionEntity from '../../../../hooks/useSessionEntity';
import { loading } from '../../../../actions/sessions';

export default function ApplyNowIntro() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const merchant = useSessionEntity('merchant');
  const session = useSelector((state) => state.sessions.get('session'));

  useEffect(() => {
    dispatch(loading(false));
  }, []);

  if (!merchant) return <Loading className="w-14 h-14" containerClass="my-24 sm:my-0" />;

  return (
    <div id="content" role="main"  className="mx-6 sm:mx-auto">
      <div className="mt-4 mb-1 sm:mb-4">
        <p className="text-xl sm:text-3xl font-bold">
          Buy what you need, pay over time
        </p>
        <div className="my-2 sm:my-4 font-light text-sm sm:text-lg">
          <p>Fast, Easy Financing with No Money Down. Instant decisions.</p>
          <p>
            Checking your rate will <strong className="text-black">not</strong>{' '}
            affect your credit score.
          </p>
        </div>
        <div className="flex flex-col xs:flex-row items-center w-full gap-6 sm:gap-12 my-3 sm:my-10">
          <img src={payOnline} alt="pay-online-vector" className="w-52" />
          <div className="border-[1.5px] rounded-md border-primary-border-gray px-4 py-3 flex flex-col gap-2 w-full">
            <div className="flex items-center gap-2">
              <span className="fa-layers fa-fw text-2xl sm:text-3xl">
                <FontAwesomeIcon
                  icon="square"
                  className="text-secondary-blue"
                />
                <FontAwesomeIcon
                  icon="circle-check"
                  className="text-primary-blue text-sm sm:text-xl"
                />
              </span>
              <div className="text-sm sm:text-base text-primary-blue font-semibold tracking-wide">
                Up to {session?.hasCustomFlag('vip') ? '$200k' : '$50k'}{' '}
                financing
              </div>
            </div>
            <div className="flex items-center gap-2">
              <span className="fa-layers fa-fw text-2xl sm:text-3xl">
                <FontAwesomeIcon
                  icon="square"
                  className="text-secondary-blue"
                />
                <FontAwesomeIcon
                  icon="circle-check"
                  className="text-primary-blue text-sm sm:text-xl"
                />
              </span>
              <div className="text-sm sm:text-base text-primary-blue font-semibold tracking-wide">
                Pay 0% for
                <span className="mx-1">
                  {merchant.get('active_promo')
                    ? merchant.get('active_promo').get('max_net_duration')
                    : 30}
                </span>
                days
              </div>
            </div>
            <div className="flex items-center gap-2">
              <span className="fa-layers fa-fw text-2xl sm:text-3xl">
                <FontAwesomeIcon
                  icon="square"
                  className="text-secondary-blue"
                />
                <FontAwesomeIcon
                  icon="circle-check"
                  className="text-primary-blue text-sm sm:text-xl"
                />
              </span>
              <div className="text-sm sm:text-base text-primary-blue font-semibold tracking-wide">
                Up to 12 month terms
              </div>
            </div>
          </div>
        </div>
        <PrimaryButton
          ariaLabel="apply-now-button"
          buttonText="Let's Get Started"
          buttonColor="bg-primary-blue"
          hoverClass="hover:bg-primary-blue-hover"
          onClick={() =>
            history.push(
              location.state ? location.state.redirect_url : location.pathname,
            )
          }
        />
        <OutlineButton
          buttonText="Already have a Credit Key account? Sign In"
          buttonColor="border-primary-blue text-primary-blue hover:text-primary-blue-hover hover:border-primary-blue-hover"
          onClick={() => history.push('/init/existing_user')}
        />
      </div>
    </div>
  );
}
