import React from 'react';
import { useHistory } from 'react-router-dom';

import { ModalWrapper } from '.';
import OutlineButton from '../buttons/OutlineButton';
import PrimaryButton from '../buttons/PrimaryButton';

export default function AddressMatchModal({
  businessAddress,
  handleSubmit,
  isLoading,
  isModalActive,
  personalAddress,
  setForceAddress,
  toggleModal,
}) {
  const history = useHistory();

  return (
    <ModalWrapper isModalActive={isModalActive} toggleModal={toggleModal}>
      <div className="px-2 sm:p-3" role={AddressMatchModal.role}>
        <header className="text-lg sm:text-2xl font-semibold text-primary-blue mb-4">
          Confirmation Required
        </header>
        <section>
          <div className="flex flex-col gap-4 mb-2 modal:mb-0">
            <p className="font-light text-sm sm:text-base">
              You entered the same address for your business & your personal
              residence. Please confirm that this information is correct or
              update your address.
            </p>
            <div className="flex flex-col sm:flex-row sm:items-center gap-4 sm:gap-6 text-sm">
              <div className="flex flex-col gap-1 sm:gap-2 sm:w-1/2">
                <p className="font-semibold text-secondary-gray">
                  Business Address
                </p>
                <div>
                  <p>
                    {businessAddress.address1} {businessAddress.address2}
                    <span className="inline sm:block">
                      {businessAddress.city}, {businessAddress.state}{' '}
                      {businessAddress.zip}
                    </span>
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-1 sm:gap-2 sm:w-1/2">
                <p className="font-semibold text-secondary-gray">
                  Personal Address
                </p>
                <div>
                  <p>
                    {personalAddress.address1} {personalAddress.address2}
                    <span className="inline sm:block">
                      {personalAddress.city}, {personalAddress.state}{' '}
                      {personalAddress.zip}{' '}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="sm:flex sm:justify-between sm:gap-4">
              <OutlineButton
                buttonText="Update Business Address"
                buttonColor="border-primary-blue text-primary-blue hover:text-primary-blue-hover hover:border-primary-blue-hover"
                isSmall={true}
                onClick={() => history.push('/init/new_business_details')}
              />
              <OutlineButton
                buttonText="Update Personal Address"
                buttonColor="border-primary-blue text-primary-blue hover:text-primary-blue-hover hover:border-primary-blue-hover"
                isSmall={true}
                onClick={toggleModal}
              />
            </div>
            <p className="font-light text-secondary-gray text-xs sm:text-sm tracking-wide">
              *Note that inaccurate information may result in a declined
              application if we are unable to verify your address.
            </p>
            <p className="text-sm sm:text-base font-light">
              I understand confirming will submit my application with the above
              personal & business addresses
            </p>
          </div>
          <PrimaryButton
            buttonText="Confirm & Continue"
            buttonColor="bg-primary-blue"
            hoverClass="hover:bg-primary-blue-hover"
            disabled={isLoading}
            isLoading={isLoading}
            onClick={() => {
              setForceAddress(true);
              handleSubmit();
              toggleModal();
            }}
          />
        </section>
      </div>
    </ModalWrapper>
  );
}

AddressMatchModal.role = 'address-match-modal-component';
