import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function OutlineButton({
  buttonText,
  buttonColor,
  isSmall,
  icon,
  onClick,
  disabled,
  isLoading,
}) {
  return (
    <div className="flex w-full">
      <button
        className={classNames(
          'w-full font-semibold transition duration-150 ease-in-out cursor-pointer inline-block mx-auto my-1 sm:my-2 tracking-wider rounded-lg flex justify-center items-center border-[1.5px]',
          buttonColor,
          isSmall ? 'py-2 px-3 text-xs' : 'py-2 sm:py-3 px-4 text-xs md:text-sm',
          {
            'cursor-not-allowed border-disabled hover:border-disabled text-disabled hover:text-disabled':
              disabled,
          },
        )}
        type="submit"
        role="submit"
        onClick={onClick}
        disabled={disabled}
      >
        <div className="flex justify-center items-center">
          <span className="hidden xs:inline">
            {icon && !isLoading && (
              <FontAwesomeIcon icon={icon} className="mr-2" />
            )}
            {isLoading && (
              <FontAwesomeIcon
                className="mr-2"
                icon={['far', 'spinner-third']}
                spin
              />
            )}
          </span>
          <span>{buttonText}</span>
        </div>
      </button>
    </div>
  );
}
