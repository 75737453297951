import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ProgressBars from '../../../shared/ProgressBars';
import ReturnToMerchant from '../../../shared/ReturnToMerchant';
import Support from '../../../shared/Support';
import { borrowerPortalUrl } from '../../../../lib/constants/SiteVariables';

export default function Default({ merchant }) {
  return (
    <div>
      <ProgressBars />
      <div className="my-4 flex items-center">
        <span className="fa-layers fa-fw text-5xl">
          <FontAwesomeIcon
            icon="square"
            className="text-background-success !mx-0"
          />
          <FontAwesomeIcon
            icon={['far', 'circle-check']}
            className="text-success text-3xl !mx-1.5"
          />
        </span>
        <div className="text-2xl sm:text-4xl font-extrabold">Approved</div>
      </div>
      <div className="font-light sm:text-lg tracking-wide mb-4 flex flex-col gap-4">
        <div>
          You’re good to go! We told you it would be easy. Simply choose
          <span className="font-semibold text-primary-blue mx-1">
            Credit Key
          </span>
          at checkout.
        </div>

        <div>
          Access your account at
          <a
            className="font-semibold ml-1 text-primary-blue"
            target="_blank"
            href={borrowerPortalUrl}
            rel="noreferrer"
          >
            www.creditkey.com
          </a>
        </div>
      </div>

      <div className="my-4 sm:my-10">
        <ReturnToMerchant merchant={merchant} asButton={true} />
      </div>
      <Support />
    </div>
  );
}
