import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import ApplyNow from './apply/ApplyNow';
import AuthorizePurchaser from './apply/AuthorizePurchaser';
import BankCredentials from './purchase/BankCredentials';
import Loading from '../shared/Loading';
import NotifyOwner from './apply/NotifyOwner';
import Ownership from './apply/Ownership';
import Personal from './apply/Personal';
import NonPGPersonal from './apply/NonPGPersonal';
import PhoneVerification from './purchase/PhoneVerification';
import PinVerification from './purchase/PinVerification';
import ProcessApplication from './apply/ProcessApplication';
import RechargeSettings from './apply/RechargeSettings';
import StateRestrictedProducts from './apply/StateRestrictedProducts';
import useSessionEntity from '../../hooks/useSessionEntity';

export default function Apply({match}) {
  const publicKey = useSelector(state => state.sessions.get('public_key'));
  const isLoading = useSelector(state => state.sessions.get('loading'));
  const application = useSessionEntity('application');

  if (!publicKey && !isLoading) {
    return (<Redirect to="/invalid" />);
  }

  const isReady = (Component, routeProps) => {
    return application ? <Component {...routeProps} /> : <Loading className="w-14 h-14" />;
  }

  return (
    <Switch>
      <Route path={`${match.path}/authorize_purchaser`} render={routeProps => isReady(AuthorizePurchaser, routeProps)} />
      <Route path={`${match.path}/ownership`} render={routeProps => isReady(Ownership, routeProps)} />
      <Route path={`${match.path}/notify_owner`} render={routeProps => isReady(NotifyOwner, routeProps)} />
      <Route path={`${match.path}/state_restricted_products`} render={routeProps => isReady(StateRestrictedProducts, routeProps)} />
      <Route path={`${match.path}/personal`} render={routeProps => isReady(application?.is_enterprise ? NonPGPersonal : Personal , routeProps)} />
      <Route path={`${match.path}/phone`} render={routeProps => isReady(PhoneVerification, routeProps)} />
      <Route path={`${match.path}/verify/:type/:contact`} render={routeProps => isReady(PinVerification, routeProps)} />
      <Route path={`${match.path}/process_application`} render={routeProps => isReady(ProcessApplication, routeProps)} />
      <Route path={`${match.path}/require_bank_credentials`} component={BankCredentials} />
      <Route path={`${match.path}/recharge_settings`} component={RechargeSettings} />
      <Route path={`${match.path}/:merchant_public_key/:prequalification_id?`} component={ApplyNow} />
    </Switch>
  );
}
