import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Error from '../../shared/Error';
import Loading from '../../shared/Loading';
import useRouteStatus from '../../../hooks/useRouteStatus';
import useSessionEntity from '../../../hooks/useSessionEntity';
import { asyncActions } from '../../../actions/general';
import { getRoute, loading } from '../../../actions/sessions';
import { verifyShoppingCart } from '../../../actions/shopping_carts';

export default function ResumeCart({ location }) {
  const dispatch = useDispatch();
  const shopping_cart = useSessionEntity('shopping_cart');

  useRouteStatus(location);

  useEffect(() => {
    dispatch(loading(true));
    dispatch(
      asyncActions(
        shopping_cart?.using_virtual_card && verifyShoppingCart(shopping_cart),
        getRoute(),
      ),
    ).then(() => dispatch(loading(false)));
  }, []);

  return (
    <div id="content">
      <Error />
      <div className="text-center">
        <Loading className="w-14 h-14" containerClass="my-24 sm:my-0" />
      </div>
    </div>
  );
}
