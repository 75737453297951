import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { history, store } from './store';
import { platform } from './lib/constants/SiteVariables';
import App from './App';
import GA from './lib/utils/ga';
import Heap from './lib/utils/heap';
import FullStory from './lib/utils/full_story';
import 'react-popper-tooltip/dist/styles.css';
import './index.css';

const heap = new Heap();
heap.load(platform());

const ga = new GA();
ga.init();

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
);

if (module.hot) {
  module.hot.accept(function (err) {
    console.log(err, 'An error occurred while accepting new version');
  });
}
