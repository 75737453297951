import * as FS from '@fullstory/browser';

const iframedMerchants = ['chargeafter', 'samsung', 'lenovo'];

export default class FullStory {
  constructor(component = '', merchant) {
    this.component = component.toLowerCase();
    this.merchantName = merchant?.name?.toLowerCase() || '';
  }

  initialized() {
    return process.env.NODE_ENV === 'test' ? true : FS.isInitialized();
  }

  init(platform) {
    FS.init({
      orgId: 'Y34CM',
      devMode: platform !== 'production',
      recordCrossDomainIFrames: iframedMerchants.includes(this.merchantName) ? true : false,
      recordOnlyThisIFrame: iframedMerchants.includes(this.merchantName) ? true : false
    });
  }

  identify(id, vars={}) {
    if (process.env.NODE_ENV !== 'production') return false;

    FS.identify(id, vars);
  }

  event({merchant = {}, session = {}, user = {}, shopping_cart = {}, company = {}}) {
    if (process.env.NODE_ENV !== 'production') return false;

    switch (this.component) {
      case 'newuser':
        FS.event('New User', {
          order_amount: session.hasShoppingCart() ? shopping_cart.grand_total : '',
          merchant_public_key: merchant.public_key,
          merchant_name: merchant.name,
          session_type: session.session_type,
          email: user.email
        });
        break;

      case 'existinguser':
        FS.event('Existing User', {
          order_amount: session.hasShoppingCart() ? shopping_cart.grand_total : '',
          merchant_public_key: merchant.public_key,
          merchant_name: merchant.name,
          session_type: session.session_type,
          email: user.email
        });
        break;

      case 'declinedonreapply':
        FS.event('Declined on Reapply', {
          fico: company.applicant_fico_score,
          underwriting_status: company.underwriting_status,
          tier: company.tier,
          tcl: company?.credit_line?.get('amount') || 0,
          reason: company.short_display
        });
        break;

      case 'declined':
        FS.event('Decline', {
          fico: company.applicant_fico_score,
          underwriting_status: company.underwriting_status,
          tier: company.tier,
          tcl: company?.credit_line?.get('amount') || 0,
          reason: company.short_display
        });
        break;

      case 'pending':
        FS.event('Pend', {
          fico: company.applicant_fico_score,
          underwriting_status: company.underwriting_status,
          tier: company.tier,
          tcl: company?.credit_line?.get('amount') || 0,
          reason: company.short_display
        });
        break;

      case 'terms':
        FS.event('Terms', {
          session_type: session.session_type,
          fico: company.applicant_fico_score,
          order_amount: session.hasShoppingCart() ? shopping_cart.grand_total : '',
          underwriting_status: company.underwriting_status,
          tier: company.tier,
          tcl: company?.credit_line?.get('amount') || 0,
          reason: company.short_display
        });
        break;
    };
  }
}
