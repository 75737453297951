const path = resource => {
  if (!resource) resource = '';
  return { resource: 'api/' + resource };
}

export const getIndustryTypes = () => {
  return (dispatch, getState, api) => {
    return api.get(path('industries'))
  }
};
