import { Record } from 'immutable';
import { convertRawValues } from '../lib/utils/record_utils';
import { subDomains } from '../lib/constants/SiteVariables';

export default class SessionRecord extends Record({
  id: null,
  disposition: '',
  application_id: null,
  company_id: null,
  merchant_id: null,
  shopping_cart_id: null,
  user_id: null,
  prequalified_user_id: null,
  promo_id: null,
  public_key: '',
  session_type: null,
  workflow: '',
  action: '',
  mode: '',
  strategy: '',
  referring_session_id: null,
  customer_checkout_url: null,
  session_key: null,
  custom_flags: []
}) {
  constructor(data) {
    data = convertRawValues(data);

    super(data);
  }

  depId(dep) {
    if (!this.has(dep + '_id')) throw 'invalid dependency';

    return this[dep + '_id'] ? this[dep + '_id'] : null;
  }

  loadDep(type, dispatch, action) {
    if (!this.has(type + '_id')) throw 'invalid dependency';

    return this.depId(type) && dispatch(action(this.depId(type)));
  }

  hasApplication() {
    return !!(this.has('application_id') && this.application_id);
  }

  hasShoppingCart() {
    return !!(this.has('shopping_cart_id') && this.shopping_cart_id);
  }

  currentRoute() {
    if (!this.has('workflow') || !this.has('action')) return null;

    return `/${this.workflow}/${this.action}`;
  }

  hasCustomFlag(flag) {
    return this.custom_flags.includes(flag);
  }

  setCustomFlag(flag) {
    if (!subDomains.authorized(flag)) return this;

    return this.set('custom_flags', [...new Set(this.custom_flags.push(flag))]);
  }

  removeCustomFlag(flag) {
    if (!this.custom_flags.includes(flag)) return this;

    const index = this.custom_flags.indexOf(flag);
    return this.set('custom_flags', [
      ...new Set(this.custom_flags.splice(index, 1)),
    ]);
  }
}
