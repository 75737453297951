import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getRoute,
  getSession,
  loading,
  resubmitApplication,
} from '../../../actions/sessions';
import Error from '../../shared/Error';
import Loading from '../../shared/Loading';
import useRouteStatus from '../../../hooks/useRouteStatus';
import { asyncActions } from '../../../actions/general';

export default function RerunCredit({ location }) {
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.sessions.get('loading'));
  const session = useSelector((state) => state.sessions.get('session'));

  useRouteStatus(location);

  useEffect(() => {
    dispatch(loading(true));
    dispatch(
      asyncActions(resubmitApplication(session), getSession(), getRoute()),
    ).then((res) => dispatch(loading(false)));
  }, []);

  return (
    <div id="content">
      <Error />
      {isLoading && (
        <Loading className="w-14 h-14" containerClass="my-24 sm:my-0" />
      )}
    </div>
  );
}
