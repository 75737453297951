import { fromJS, Record } from 'immutable';
import moment from 'moment';

import AddressRecord from './address';
import { normalizePhone } from '../lib/utils/formatters';
import { convertRawValues } from '../lib/utils/record_utils';

import { decisions } from '../lib/constants/SiteVariables';

export default class ApplicationRecord extends Record({
  id: null,
  session_id: null,
  business_type: '',
  is_authorized_signer: false,
  first_name: '',
  last_name: '',
  ein: '',
  title: '',
  email: '',
  phone: '',
  business_address: new AddressRecord(),
  business_phone: '',
  birthdate: '',
  date_established: '',
  company_name: '',
  dba: '',
  raw_annual_revenue: '',
  trade_credit_amount_requested: '',
  industry: '',
  personal_address: new AddressRecord(),
  ssn: '',
  status: '',
  interest_percent: 0,
  beneficial_owners: fromJS([]),
  are_additional_owners_confirmed: false,
  previous_tcl: '',
  sells_restricted_products: false,
  has_authorized_purchaser: false,
  reasons: [],
  phone_change_attempts: 0,
  applicant_type: '',
}) {
  constructor(data) {
    data = convertRawValues(data);

    if (data && data.get('business_address')) {
      data = data.set('business_address', new AddressRecord(data.get('business_address')));
    }

    if (data && data.get('personal_address')) {
      data = data.set('personal_address', new AddressRecord(data.get('personal_address')));
    }

    if (data && data.get('birthdate')) {
      const birthdate = moment(data.get('birthdate'), 'MM/DD/YYYY');

      if (birthdate.isValid()) {
        data = data.set('birthdate', birthdate.format('YYYY-MM-DD'));
      }
    }

    if (data && data.get('phone')) data = data.set('phone', normalizePhone(data.get('phone')));
    if (data && data.get('business_phone')) data = data.set('business_phone', normalizePhone(data.get('business_phone')));

    super(data);
  }

  get applicant_full_name() {
    return `${this.first_name || ''} ${this.last_name || ''}`.trim();
  }

  get submitted() {
    return decisions[this.status.toUpperCase()];
  }

  get is_enterprise() {
    return this.applicant_type == 'enterprise';
  }
}
