import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Tooltip from './Tooltip';
import { formatCurrency } from '../../lib/utils/formatters';

export default function Term({
  parentSelectedTerm,
  setParentTerm,
  term,
  cart,
  promo,
}) {
  const [selectedTerm, setSelectedTerm] = useState(parentSelectedTerm);

  useEffect(() => {
    if (setParentTerm) setParentTerm(selectedTerm);
  }, [selectedTerm]);

  const termOnClick = (term) => {
    setSelectedTerm({
      term: parseInt(term.get('term')),
      term_period: term.get('term_period'),
      payment: parseFloat(term.get('monthly_payment')),
    });
  };

  function Interest() {
    return (
      <>
        <div className="flex flex-col sm:flex-row items-center justify-center text-secondary-gray">
          <p className="text-xs sm:text-sm font-semibold">
            {term.get('total_interest') < 1 && 'No'}
            {term.get('total_interest') >= 1 &&
              formatCurrency(term.get('total_interest'))}{' '}
            {term.get('total_interest') >= 1 && (
              <span className="inline sm:hidden">total </span>
            )}
            interest
          </p>
          <span className="hidden sm:inline text-secondary-gray mx-2 mb-0.5">
            •
          </span>
          {cart && cart.processing_fee > 0.0 && (
            <>
              <p className="text-xs sm:text-sm font-semibold">
                {formatCurrency(term.get('total_fees'))} service fee
              </p>
              <span className="hidden sm:inline text-secondary-gray mx-2 mb-0.5">
                •
              </span>
            </>
          )}
          <p className="text-xs sm:text-sm">
            First payment {promo ? promo.get('max_net_payment') + 1 : 31} days
            after shipment
          </p>
        </div>
      </>
    );
  }

  if (term && term.get('term_period') === 'biweekly') {
    return (
      <>
        <div
          className={classNames(
            'mb-1 p-3 border rounded-md shadow my-2.5 mx-auto',
            {
              'cursor-pointer': setParentTerm,
              'border-[3px] border-success':
                parentSelectedTerm &&
                term.get('term') === parentSelectedTerm.term,
              'border-secondary-gray':
                parentSelectedTerm &&
                term.get('term') !== parentSelectedTerm.term,
            },
          )}
          key={term.get('term')}
          id={'term-' + term.get('term')}
          data-term={term.get('term')}
          data-payment={formatCurrency(term.get('monthly_payment'))}
          onClick={() => termOnClick(term)}
        >
          <div className="text-sm sm:text-base text-center font-semibold">
            4 payments of{' '}
            <span className="text-success">${term.get('monthly_payment')}</span>
          </div>
          <div className="text-xs sm:text-sm text-center text-secondary-gray flex items-center justify-evenly py-1 sm:px-4">
            $0 Interest
            <span>•</span>
            Every 2 weeks
            <span>•</span>
            First payment today
          </div>
        </div>
        <div className="text-lg text-center w-full border-b-[2.5px] mt-6 h-[17px]">
          <span className="sm:text-lg font-semibold bg-white p-6">
            Or pay over time
          </span>
        </div>
        <div className="text-center text-secondary-gray text-sm sm:text-base p-3 sm:p-6">
          No early pay-off fees / 0% for 30 days
        </div>
      </>
    );
  }

  if (term && term.get('available')) {
    return (
      <div
        className={classNames('border rounded-md shadow p-2.5 my-2.5 mx-auto', {
          'cursor-pointer': setParentTerm,
          'border-[3px] border-success':
            parentSelectedTerm && term.get('term') === parentSelectedTerm.term,
          'border-secondary-gray':
            parentSelectedTerm && term.get('term') !== parentSelectedTerm.term,
        })}
        key={term.get('term')}
        id={'term-' + term.get('term')}
        data-term={term.get('term')}
        data-payment={formatCurrency(term.get('monthly_payment'))}
        onClick={() => termOnClick(term)}
      >
        <div className="flex-1 whitespace-nowrap">
          <div className="flex items-center justify-center space-x-3 mb-1 sm:mb-0">
            <h3 className="text-sm sm:text-lg font-semibold">
              {term.get('term') === 30 && term.get('term_period') === 'days' ? (
                <span className="mx-1">Net 30</span>
              ) : (
                <span className="text-sm sm:text-base">
                  {term.get('term')}{' '}
                  {term.get('term') === 1 ? ' month ' : ' monthly payments of '}
                </span>
              )}
              <span className="text-success ml-1">
                {formatCurrency(term.get('monthly_payment'))}
              </span>
            </h3>
          </div>
          <div className="text-center text-lg">{Interest()}</div>
        </div>
      </div>
    );
  }

  if (term && !term.get('available')) {
    return (
      <div
        className="mb-4 border rounded-md shadow p-3 my-2.5 mx-auto term-unavailable"
        key={term.get('id')}
        id={'term-' + term.get('id')}
        data-term={term.get('term')}
        data-payment={formatCurrency(term.get('monthly_payment'))}
      >
        <div className="text-center">
          <div>
            <p>
              <div className="text-sm sm:text-lg font-semibold">
                {term.get('term')} {term.get('term') === 1 ? 'month' : 'months'}
              </div>
            </p>
          </div>
          <div>
            <div className="text-error font-semibold">
              <span className="mx-1">Unavailable</span>
              <Tooltip
                tooltipText="This option exceeds your payback capacity"
                placement="top-start"
                triggerClass="px-0.5 mt-1 text-xs sm:text-sm hover:cursor-pointer hover:text-primary-blue text-left"
                containerClass="rounded-lg w-72 p-3 bg-border-gray text-xs"
              >
                <FontAwesomeIcon
                  icon={['fal', 'info-circle']}
                  className="text-sm sm:text-base"
                />
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
