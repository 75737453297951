import moment from 'moment';
import {
  getLocalItem,
  setLocalItem,
  removeLocalItem,
  isLocalItemExpired
} from '../utils/local_storage';

const defaultLabel = 'creditkey';

const removeAuthorizationIfExpired = data => {
  if (data && isLocalItemExpired(data)) data = pdRemoveToken(data);

  return data;
};

const pdRemoveToken = data => {
  const key = Object.keys(data)[0];

  data[key].user = {};

  setLocalItem(defaultLabel, data);

  return getLocalItem(defaultLabel);
};

export const pdExpire = () => {
  const key = pdGetPublicKey();

  setLocalItem(defaultLabel, {
    [key]: {
      user: {}
    },
    timestamp: moment().subtract(5, 'hours').toISOString(),
  });
};

export const pdGetPublicKey = () => {
  const ck = removeAuthorizationIfExpired(getLocalItem(defaultLabel));

  if (ck) return Object.keys(ck)[0];
  return false;
};

export const pdSavePublicKey = key => {
  setLocalItem(defaultLabel, {
    [key]: {
      user: {}
    },
    timestamp: moment().toISOString(),
  });
};

export const pdRemoveCreditKey = () => {
  removeLocalItem(defaultLabel);
};
