import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Error from '../../shared/Error';
import Loading from '../../shared/Loading';
import PrimaryButton from '../../shared/buttons/PrimaryButton';
import ProgressBars from '../../shared/ProgressBars';
import Support from '../../shared/Support';
import useSession from '../../../hooks/useSession';
import useSessionEntity from '../../../hooks/useSessionEntity';
import { asyncActions } from '../../../actions/general';
import { borrowerPortalUrl } from '../../../lib/constants/SiteVariables';
import { confirmInstoreShoppingCart } from '../../../actions/shopping_carts';
import { loading } from '../../../actions/sessions';

export default function InstoreConfirm() {
  const dispatch = useDispatch();
  const merchant = useSessionEntity('merchant');
  const cart = useSessionEntity('shopping_cart');
  const [errorFound, setErrorFound] = useState(false);
  const [loadingCart, setLoadingCart] = useState(false);
  const [session, sessionActions] = useSession();

  useEffect(() => {
    if (cart.status !== 'ordered') {
      dispatch(loading(true));
      setLoadingCart(true);
      dispatch(asyncActions(confirmInstoreShoppingCart(cart)))
        .then(() => {
          setLoadingCart(false);
          dispatch(loading(false));
        })
        .then(() => sessionActions.finish())
        .catch((err) => {
          setErrorFound(true);
        });
    } else {
      setErrorFound(true);

      sessionActions.finish();
    }
  }, []);

  if (errorFound) {
    return (
      <div role="main"  className="mx-6 sm:mx-auto">
        <div className="my-4 flex items-center">
          <span className="fa-layers fa-fw text-5xl">
            <FontAwesomeIcon
              icon="square"
              className="text-background-error !mx-0"
            />
            <FontAwesomeIcon
              icon={['far', 'triangle-exclamation']}
              className="text-error text-3xl !mx-1.5"
            />
          </span>
          <div className="text-xl sm:text-4xl font-extrabold">We're sorry!</div>
        </div>
        <div className="tracking-wide mb-6 font-light sm:text-lg">
          Something isn't right with your order. Please contact support for
          further guidance.
        </div>
        <Support />
      </div>
    );
  }

  if (loadingCart) {
    return (
      <div role="main"  className="mx-6 sm:mx-auto">
        <p className="text-xl sm:text-4xl font-extrabold mb-6 text-center">
          Just a few more minutes...
        </p>
        <Loading className="w-14 h-14" />
      </div>
    );
  }

  return (
    <div role="main"  className="mx-6 sm:mx-auto">
      <ProgressBars />
      <div className="my-4 flex items-center">
        <span className="fa-layers fa-fw text-5xl">
          <FontAwesomeIcon
            icon="square"
            className="text-background-success !mx-0"
          />
          <FontAwesomeIcon
            icon={['far', 'circle-check']}
            className="text-success text-3xl !mx-1.5"
          />
        </span>
        <div className="text-xl sm:text-4xl font-extrabold">
          Congratulations!
        </div>
      </div>

      <Error />

      <div className="font-light text-lg tracking-wide mb-4 flex flex-col gap-2 sm:gap-4">
        <div className="font-semibold sm:text-lg tracking-wide">
          You’re good to go! You're now setup for{' '}
          {session.hasCustomFlag('instore')
            ? 'purchases with Credit Key'
            : 'Credit Key Recharge'}
          .
        </div>
        <div className="text-sm sm:text-base">
          Your {merchant.name} order has been processed and your default
          settings have been saved. In the future, your sales rep will be able
          to place orders on your behalf, using your selected terms.
        </div>

        <div className="font-semibold text-sm sm:text-lg tracking-wide mt-4 sm:mt-6">
          Want to change your default settings?
        </div>
        <div className="text-sm sm:text-base">
          To update your default settings, please login to your Credit Key
          borrower portal to make changes.
        </div>
        <PrimaryButton
          buttonText="Go to My Portal"
          buttonColor="bg-primary-blue"
          hoverClass="hover:bg-primary-blue-hover"
          onClick={() => (window.location.href = borrowerPortalUrl)}
        />
      </div>

      <Support />
    </div>
  );
}
