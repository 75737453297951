import React from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';

export default function Tooltip({
  children,
  tooltipText,
  triggerClass,
  containerClass,
  placement
}) {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    trigger: ['hover', 'click'],
    placement: placement,
  })

  return (
    <>
      <span ref={setTriggerRef} className={triggerClass}>
        {children}
      </span>
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({ className: `tooltip-container ${containerClass}` })}
        >
          {tooltipText}
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
        </div>
      )}
    </>
  );
}