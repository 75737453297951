import { receiveEntity, updateEntity } from './entities';

const path = resource => {
  if (!resource) resource = '';
  return { resource: 'api/users/' + resource };
}

export const createUser = user => {
  return (dispatch, getState, api) => {
    return api.post(path(), user)
      .then(res => dispatch(receiveEntity(res)));
  }
}

export const getUser = (id, raw) => {
  return (dispatch, getState, api) => {
    return api.get(path(id))
      .then(res => raw ? res : dispatch(receiveEntity(res)));
  }
}

export const onChange = (e, user) => {
  return (dispatch, getState, api) => {
    return dispatch(receiveEntity(updateEntity(user.set(e.target.name, e.target.value), 'user')));
  }
}

export const setupNewUser = user => {
  return (dispatch, getState, api) => {
    return dispatch(receiveEntity(updateEntity(user, 'user')));
  }
}

export const updateUser = user => {
  return (dispatch, getState, api) => {
    return api.edit(path(user.id), user)
      .then(res => dispatch(receiveEntity(res)));
  }
}

export const validateBirthDate = birthdate => {
  return (dispatch, getState, api) => {
    return api.post(
      path('0/validate_birthdate'),
      { birthdate: birthdate }
    );
  }
}

export const validatePhone = phone_number => {
  return (dispatch, getState, api) => {
    return api.post(
      path('0/validate_phone_number'),
      { phone_number: phone_number }
    );
  }
}
