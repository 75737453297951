import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ApplicationRecord from '../../../records/application';
import ColumnInput from '../../shared/inputs/ColumnInput';
import Error from '../../shared/Error';
import Loading from '../../shared/Loading';
import PrimaryButton from '../../shared/buttons/PrimaryButton';
import ProgressBars from '../../shared/ProgressBars';
import StickyInput from '../../shared/inputs/StickyInput';
import useRouteStatus from '../../../hooks/useRouteStatus';
import useSessionEntity from '../../../hooks/useSessionEntity';
import useValidateRoute from '../../../hooks/useValidateRoute';
import { asyncActions } from '../../../actions/general';
import {
  emailRegex,
  checkEmail,
  emojiRegexTest,
} from '../../../lib/constants/SiteVariables';
import { updateApplication } from '../../../actions/applications';
import {
  authenticate,
  getRoute,
  getSession,
  loading,
} from '../../../actions/sessions';

export default function NonPGPersonal({ location }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector((state) => state.sessions.get('loading'));
  const application = useSessionEntity('application');
  const asyncErrors = useSelector((state) => state.general.get('errors'));
  const [foundUser, setFoundUser] = useState(false);

  useRouteStatus(location);

  useEffect(() => {
    if (foundUser) history.push('/init/existing_user');
  }, [foundUser]);

  useEffect(() => {
    if (
      asyncErrors.count() > 0 &&
      asyncErrors.find(
        (e) =>
          e &&
          (e.includes('Phone number already in use') ||
            e.includes('Email address already in use')),
      )
    ) {
      setFoundUser(true);
      dispatch(loading(false));
    }
  }, [asyncErrors]);

  const save = (values) => {
    dispatch(loading(true));

    return dispatch(
      asyncActions(
        updateApplication(
          new ApplicationRecord({
            ...values,
            phone: values['business_phone'],
          }),
        ),
        authenticate(),
        getSession({ cart: false, prequal: false, merchant: false }),
        getRoute(),
      ),
    ).then((res) => dispatch(loading(false)));
  };

  if (!useValidateRoute({ apply: true })) return <></>;

  if (!application)
    return <Loading className="w-14 h-14" containerClass="my-24 sm:my-0" />;

  return (
    <div role="main" className="mx-6 sm:mx-auto">
      <ProgressBars />
      <div className="mt-4 mb-1 sm:mb-4">
        <div className="text-xl sm:text-4xl font-extrabold">
          Personal Address & Details
        </div>
      </div>
      <div className="tracking-wide mb-3 sm:mb-6 font-light text-sm sm:text-lg">
        Provide your personal information so we can set up your account.
        Remember, applying will not affect your personal credit.
      </div>

      <Error />

      <Formik
        initialValues={application.toJS()}
        validate={async (values) => {
          const errors = {};

          Object.keys(values).forEach((key) => {
            const value = values[key];

            if (typeof value === 'string' && emojiRegexTest.test(value)) {
              errors[key] = '* Emojis are not accepted.';
            }
          });

          if (!values.first_name || values.first_name.trim().length < 2) {
            errors.first_name = '* Required';
          }
          if (!values.last_name || values.last_name.trim().length < 2) {
            errors.last_name = '* Required';
          }
          if (!values.email) {
            errors.email = '* Required';
          } else if (
            !emailRegex.test(values.email.toLowerCase()) ||
            !checkEmail(values.email.toLowerCase())
          ) {
            errors.email = '* Invalid email address';
          }

          if (!values.trade_credit_amount_requested) {
            errors.trade_credit_amount_requested = '* Required';
          } else if (
            parseInt(
              values.trade_credit_amount_requested.replace(/[^\d]/g, ''),
            ) < 1
          ) {
            errors.trade_credit_amount_requested =
              '* A valid number is required';
          }

          return errors;
        }}
        onSubmit={async (values) => {
          dispatch(loading(true));

          save(values);
        }}
      >
        {({ errors, touched, values }) => {
          return (
            <Form role="form" className="mx-auto">
              <ColumnInput
                error={{
                  first_name: errors?.first_name,
                  last_name: errors?.last_name,
                }}
                touched={{
                  first_name: touched?.first_name,
                  last_name: touched?.last_name,
                }}
                className="flex flex-col mobile:flex-row justify-between mobile:gap-x-1"
              >
                <StickyInput
                  id="first_name"
                  className="mobile:w-1/2 w-full my-1 mobile:my-0"
                  placeholder="First Name"
                  values={values}
                />

                <StickyInput
                  id="last_name"
                  className="mobile:w-1/2 w-full my-1 mobile:my-0"
                  placeholder="Last Name"
                  values={values}
                />
              </ColumnInput>

              <ColumnInput error={errors.title} touched={touched.title}>
                <StickyInput id="title" placeholder="Title" values={values} />
              </ColumnInput>

              <ColumnInput error={errors.email} touched={touched.email}>
                <StickyInput
                  id="email"
                  type="email"
                  placeholder="Email"
                  values={values}
                />
              </ColumnInput>

              <ColumnInput
                error={errors.trade_credit_amount_requested}
                touched={touched.trade_credit_amount_requested}
              >
                <StickyInput
                  inputMode="numeric"
                  type="tel"
                  id="trade_credit_amount_requested"
                  placeholder="$ Trade Credit Requested"
                  mask={Number}
                  max={999999999999}
                  thousandsSeparator=","
                  scale={0}
                  values={values}
                />
              </ColumnInput>
              <p className="text-xs mt-4 mb-2 text-secondary-gray">
                By clicking Submit Application, I authorize Credit Key to obtain
                my credit report (soft inquiry only).
              </p>
              <PrimaryButton
                buttonText="Submit Application"
                buttonColor="bg-primary-blue"
                hoverClass="hover:bg-primary-blue-hover"
                disabled={isLoading}
                isLoading={isLoading}
              />
            </Form>
          );
        }}
      </Formik>

      <p className="text-xs mt-4 sm:mt-2 text-tertiary-gray">
        The USA Patriot Act requires all financial institutions to obtain,
        verify and record information that identifies every customer. Completion
        of this documentation is required in order to comply with the USA
        Patriot Act.
      </p>
    </div>
  );
}
