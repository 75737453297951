import { Record } from 'immutable';
import { convertRawValues } from '../lib/utils/record_utils';

export default class ShoppingCartRecord extends Record({
    id: null,
    order_id: null,
    status: 'new',
    merchant_id: null,
    billing_address: {},
    shipping_address: {},
    cancel_url: '',
    return_url: '',
    grand_total: 0.0,
    base_amount: 0.0,
    approved_terms: [],
    num_payments: '',
    payment_amount: {},
    interest_percent: null,
    total_amount_paid: 0,
    pended_at: '',
    using_virtual_card: false,
    borrower_fee: 0.0,
    processing_fee: 0.0,
    term_period: 'monthly',
    term_length: 0,
    reasons: []
  }) {
    constructor(data) {
      data = convertRawValues(data);

    super(data);
  }

  get pending_completion() {
    return this.status === 'approved' && this.pended_at !== null && !this.order_id;
  }

  get processing_fee_amount() {
    return this.processing_fee * this.base_amount;
  }
}
