import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ProgressBars from '../../shared/ProgressBars';
import ReturnToMerchant from '../../shared/ReturnToMerchant';
import Support from '../../shared/Support';
import useRouteStatus from '../../../hooks/useRouteStatus';
import useSession from '../../../hooks/useSession';
import useSessionEntity from '../../../hooks/useSessionEntity';
import { updateParent } from '../../../lib/utils/modal';

export default function DeclinedOnReapply({ location }) {
  const [session, sessionActions] = useSession();
  const merchant = useSessionEntity('merchant');
  const cart = useSessionEntity('shopping_cart');

  useRouteStatus(location);

  const redirect = () => {
    if (session.mode === 'modal') {
      updateParent('complete', cart ? cart.cancel_url : '');
    } else {
      if (cart && cart.cancel_url && session.strategy !== 'async') {
        window.location.href = cart.cancel_url;
      } else if (merchant !== 'undefined') {
        window.location.href = merchant.url;
      } else {
        window.location.href = '/';
      }
    }
  };

  useEffect(() => {
    sessionActions.finish();
  }, []);

  return (
    <div role="main"  className="mx-6 sm:mx-auto">
      <ProgressBars />
      <div className="my-4 flex items-center">
        <span className="fa-layers fa-fw text-5xl">
          <FontAwesomeIcon
            icon="square"
            className="text-background-error !mx-0"
          />
          <FontAwesomeIcon
            icon={['far', 'heart-crack']}
            className="text-error text-3xl !mx-1.5"
          />
        </span>
        <div className="text-xl sm:text-4xl font-extrabold">We're sorry</div>
      </div>
      <p className="font-semibold text-lg tracking-wide mb-6">
        Credit Key is unable to extend you additional credit at this time.
      </p>
      <div className="tracking-wide mb-6 font-light text-lg">
        You'll receive an email momentarily with further details, including how
        to address any concerns.
      </div>
      <div className="tracking-wide mb-6 font-light text-lg">
        Any existing loans on your business line of credit will remain open
        until final repayments are complete. You will be eligible to reapply for
        additional credit in six months time.
      </div>

      <div className="my-4 sm:my-10">
        <ReturnToMerchant
          merchant={merchant}
          redirect={redirect}
          asButton={true}
        />
      </div>
      <Support declined={true} />
    </div>
  );
}
