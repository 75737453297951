import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import PrimaryButton from './buttons/PrimaryButton';
import StickyInput from './inputs/StickyInput';
import { asyncActions, clearErrors } from '../../actions/general';
import { emailRegex } from '../../lib/constants/SiteVariables';
import { useAuth } from '../../hooks/useAuth';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faLockAlt } from '@fortawesome/pro-light-svg-icons';

library.add(faLockAlt);

export default function UserVerification({
  defaultValue, // STRING phone or email
  onSuccessPath, // STRING existing_user_token or verify
  onSubmit, // FUNCTION action creator callback on save success
  onSideEffect, // FUNCTION async callback for side effect
  onLoading, // FUNCTION action creator callback to set loading state
  isLoading, // VAR loading state
  icon = true,
  buttonText = 'Next',
}) {
  const dispatch = useDispatch();
  const { verify } = useAuth();
  const history = useHistory();
  const [valid, setValid] = useState(false);
  const [label, setLabel] = useState('Mobile Phone');
  const [type, setType] = useState('phone');
  const asyncErrors = useSelector((state) => state.general.get('errors'));

  const inputType = type === 'phone' ? 'tel' : 'text';

  function detect_input_type(value) {
    const phoneRegex = /^\d{0,4}$/;
    const emailRegex = /^[a-zA-Z0-9._-]{0,4}$/;

    const val = value.replace(/[\W]/g, '').slice(0, 4);

    if (val && phoneRegex.test(val)) {
      setLabel('Mobile Phone');
      return 'phone';
    } else if (val && emailRegex.test(val.toLowerCase())) {
      setLabel('Email');
      return 'email';
    }

    return 'unknown';
  }

  const sideEffect = (values) =>
    new Promise((resolve, reject) => {
      if (onSideEffect)
        return onSideEffect(values.contact)
          .then(() => resolve())
          .catch((err) => reject(err));

      if (!onSideEffect) return resolve();
    });

  const save = (values) => {
    dispatch(onLoading(true));

    return sideEffect(values)
      .then(() =>
        dispatch(
          asyncActions(
            verify(detect_input_type(values.contact), values.contact),
          ),
        ),
      )
      .then((res) => {
        if (res.require_pin_verification) {
          history.push(
            onSuccessPath +
              '/' +
              detect_input_type(values.contact) +
              '/' +
              values.contact.replace(/\s+/g, ''),
          );
        } else {
          onSubmit
            ? dispatch(
                onSubmit(
                  detect_input_type(values.contact),
                  values.contact.replace(/\s+/g, ''),
                ),
              )
            : history.push(
                onSuccessPath +
                  '/' +
                  detect_input_type(values.contact) +
                  '/' +
                  values.contact.replace(/\s+/g, ''),
              );
        }
      })
      .then(() => dispatch(onLoading(false)))
      .catch((err) => dispatch(onLoading(false)));
  };

  // this catches a borrower that started out at NewUser and changed the email or phone to an existing account
  // we need to clear the existing error to allow the right outcome if the user goes back to NewUser
  // we need to set existing user state to look for at UserTokenVerification to setup the correct async events
  useEffect(() => {
    setType(detect_input_type(defaultValue));

    if (
      asyncErrors.count() > 0 &&
      asyncErrors.find((e) => e && e.includes('User already registered'))
    ) {
      dispatch(clearErrors());
    }
  }, []);

  return (
    <div role="form">
      <Formik
        initialValues={{
          contact: defaultValue,
        }}
        onSubmit={(values, { setSubmitting }) => {
          save(values);
        }}
      >
        {({ isSubmitting, errors, values }) => (
          <Form>
            <div className="my-3 xxs:my-6">
              <StickyInput
                id="contact"
                name="contact"
                type={inputType}
                placeholder={label}
                values={values}
                mask={[
                  { ckType: 'unknown', mask: '**********' },
                  {
                    ckType: 'phone',
                    mask: '(999) 999-9999',
                    definitions: { 9: /\d/ },
                  },
                  { ckType: 'email', mask: /^\S*@?\S*$/gm },
                ]}
                dispatch={(appended, masked, flags) => {
                  const typed = `${masked.value}${appended}`;
                  const newMask = masked.compiledMasks.find((m) => {
                    return m.ckType === detect_input_type(typed);
                  });

                  if (newMask.ckType === 'email') {
                    setValid(emailRegex.test(typed.toLowerCase()));
                  } else {
                    setValid(newMask.isComplete);
                  }

                  return newMask;
                }}
              />
            </div>
            <PrimaryButton
              ariaLabel="login-button"
              buttonText={buttonText}
              buttonColor="bg-primary-blue"
              hoverClass="hover:bg-primary-blue-hover"
              icon={['far', 'lock-alt']}
              disabled={!valid || isLoading}
              isLoading={isLoading}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}
