import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ReturnToMerchant from '../../shared/ReturnToMerchant';
import Support from '../../shared/Support';
import useRouteStatus from '../../../hooks/useRouteStatus';
import useSession from '../../../hooks/useSession';
import useSessionEntity from '../../../hooks/useSessionEntity';
import { updateParent } from '../../../lib/utils/modal';

export default function Restricted({ location }) {
  const [session, sessionActions] = useSession();
  const merchant = useSessionEntity('merchant');
  const cart = useSessionEntity('shopping_cart');

  useRouteStatus(location);

  const redirect = () => {
    if (session.mode === 'modal') {
      updateParent('complete', cart ? cart.cancel_url : '');
    } else {
      if (cart && cart.cancel_url && session.strategy !== 'async') {
        window.location.href = cart.cancel_url;
      } else if (merchant !== 'undefined') {
        window.location.href = merchant.url;
      } else {
        window.location.href = '/';
      }
    }
  };

  useEffect(() => {
    sessionActions.finish();
  }, []);

  return (
    <div role="main"  className="mx-6 sm:mx-auto">
      <div className="my-4 flex items-center">
        <span className="fa-layers fa-fw text-5xl">
          <FontAwesomeIcon
            icon="square"
            className="text-background-error !mx-0"
          />
          <FontAwesomeIcon
            icon={['far', 'circle-xmark']}
            className="text-error text-3xl !mx-1.5"
          />
        </span>
        <div className="text-xl sm:text-4xl font-extrabold ml-3 sm:ml-6">
          Unable to proceed with your order
        </div>
      </div>

      <p className="font-light sm:text-lg tracking-wide mb-6">
        We are unable to process your application right now due to a previously
        declined credit application.
      </p>

      <div className="my-4 sm:my-10">
        <ReturnToMerchant
          asButton={true}
          merchant={merchant}
          redirect={redirect}
        />
      </div>
      <Support />
    </div>
  );
}
