import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PrimaryButton from './shared/buttons/PrimaryButton';
import ReturnToMerchant from './shared/ReturnToMerchant';
import Support from './shared/Support';
import useSessionEntity from '../hooks/useSessionEntity';

export default function ErrorPage() {
  const merchant = useSessionEntity('merchant');

  return (
    <div role="main" className="mx-6 sm:mx-auto">
      <div className="my-4 flex items-center">
        <span className="fa-layers fa-fw text-5xl">
          <FontAwesomeIcon
            icon="square"
            className="text-background-error !mx-0"
          />
          <FontAwesomeIcon
            icon={['far', 'circle-xmark']}
            className="text-error text-3xl !mx-1.5"
          />
        </span>
        <div className="text-xl sm:text-2xl font-extrabold ml-3">
          You have reached this page in error
        </div>
      </div>
      <div className="my-6">
        {merchant ? (
          <ReturnToMerchant asButton={true} merchant={merchant} />
        ) : (
          <PrimaryButton
            buttonText="Return to Credit Key"
            buttonColor="bg-primary-blue"
            hoverClass="hover:bg-primary-blue-hover"
            onClick={() => (window.location.href = 'https://www.creditkey.com')}
          />
        )}

        <div className="mt-6 sm:mt-12">
          <Support />
        </div>
      </div>
    </div>
  );
}
