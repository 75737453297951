import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Loading from '../../../shared/Loading';
import OutlineButton from '../../../shared/buttons/OutlineButton';
import PrimaryButton from '../../../shared/buttons/PrimaryButton';
import useSessionEntity from '../../../../hooks/useSessionEntity';
import { asyncActions } from '../../../../actions/general';
import { getRoute, loading } from '../../../../actions/sessions';

export default function InstoreIntro() {
  const dispatch = useDispatch();
  const history = useHistory();
  const merchant = useSessionEntity('merchant');
  const isLoading = useSelector((state) => state.sessions.get('loading'));

  const next = (userType) => {
    if (userType === 'new') {
      dispatch(loading(true));
      dispatch(asyncActions(getRoute())).finally(() =>
        dispatch(loading(false)),
      );
    } else {
      history.push('/init/existing_user');
    }
  };

  useEffect(() => {
    dispatch(loading(false));
  }, []);

  if (!merchant) return <Loading className="w-14 h-14" containerClass="my-24 sm:my-0" />;

  return (
    <div id="content" role="main"  className="mx-6 sm:mx-auto">
      <div className="my-4 flex items-center">
        <span className="fa-layers fa-fw text-5xl">
          <FontAwesomeIcon
            icon="square"
            className="text-secondary-blue !mx-0"
          />
          <FontAwesomeIcon
            icon={['far', 'repeat']}
            className="text-primary-blue text-3xl !mx-1.5"
          />
        </span>
        <div className="text-xl sm:text-3xl font-extrabold">
          Welcome to Credit Key
        </div>
      </div>
      <p className="my-4 font-light text-sm sm:text-lg">
        Let's get you set up for seamless, purchases with Credit Key. It takes
        less than 5 minutes.
      </p>
      <p className="font-light text-sm sm:text-lg">
        For purchases at {merchant.name}, a sales rep will place a Credit Key
        order for the purchase amount on your behalf with your default term
        length.
      </p>
      <div className="my-4 sm:my-10 border-[1.5px] rounded-md border-primary-border-gray px-4 py-3 flex flex-col gap-4 sm:gap-2 w-full">
        <div className="flex items-center">
          <span className="fa-layers fa-fw text-2xl sm:text-3xl">
            <FontAwesomeIcon icon="square" className="text-secondary-blue" />
            <FontAwesomeIcon
              icon="circle-check"
              className="text-primary-blue text-sm sm:text-xl"
            />
          </span>
          <div className="text-sm sm:text-base text-primary-blue font-semibold tracking-wide mx-4 sm:mx-2 w-full sm:w-[90%]">
            {merchant.name} sales reps can place replenishment orders on your
            behalf
          </div>
        </div>
        <div className="flex items-center">
          <span className="fa-layers fa-fw text-2xl sm:text-3xl">
            <FontAwesomeIcon icon="square" className="text-secondary-blue" />
            <FontAwesomeIcon
              icon="circle-check"
              className="text-primary-blue text-sm sm:text-xl"
            />
          </span>
          <div className="text-sm sm:text-base text-primary-blue font-semibold tracking-wide mx-4 sm:mx-2 w-full sm:w-[90%]">
            Pre-select terms from Net 30 up to 6 months or longer
          </div>
        </div>
        <div className="flex items-center">
          <span className="fa-layers fa-fw text-2xl sm:text-3xl">
            <FontAwesomeIcon icon="square" className="text-secondary-blue" />
            <FontAwesomeIcon
              icon="circle-check"
              className="text-primary-blue text-sm sm:text-xl"
            />
          </span>
          <div className="text-sm sm:text-base text-primary-blue font-semibold tracking-wide mx-4 sm:mx-2 w-full sm:w-[90%]">
            Pay early with no fee. Change your default terms at any time.
          </div>
        </div>
      </div>
      <PrimaryButton
        ariaLabel="recharge-intro-button"
        buttonColor="bg-primary-blue"
        hoverClass="hover:bg-primary-blue-hover"
        buttonText="I'm new to Credit Key"
        disabled={isLoading}
        isLoading={isLoading}
        onClick={() => next('new')}
      />
      <OutlineButton
        buttonText="I already have a Credit Key account"
        buttonColor="border-primary-blue text-primary-blue hover:text-primary-blue-hover hover:border-primary-blue-hover"
        disabled={isLoading}
        onClick={() => next('existing')}
      />
    </div>
  );
}
