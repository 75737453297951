import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Loading from '../../shared/Loading';
import ProgressBars from '../../shared/ProgressBars';
import ReturnToMerchant from '../../shared/ReturnToMerchant';
import Support from '../../shared/Support';
import useRouteStatus from '../../../hooks/useRouteStatus';
import useSession from '../../../hooks/useSession';
import useSessionEntity from '../../../hooks/useSessionEntity';
import { submitSoftApplication } from '../../../actions/applications';

export default function Vip({ location }) {
  const [session, sessionActions] = useSession();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const application = useSessionEntity('application');
  const merchant = useSessionEntity('merchant');
  useRouteStatus(location);

  useEffect(() => {
    if (application && !application.submitted) {
      setLoading(true);
      dispatch(submitSoftApplication(application))
        .then(() => sessionActions.finish())
        .then(() => setLoading(false));
    }
  }, []);

  if (loading)
    return <Loading className="w-14 h-14" containerClass="my-24 sm:my-0" />;

  return (
    <div role="main" className="mx-6 sm:mx-auto">
      <ProgressBars />
      <div className="my-4 flex items-center">
        <span className="fa-layers fa-fw text-5xl">
          <FontAwesomeIcon
            icon="square"
            className="text-secondary-blue !mx-0"
          />
          <FontAwesomeIcon
            icon={['far', 'thumbs-up']}
            className="text-primary-blue text-3xl !mx-1.5"
          />
        </span>
        <div className="text-xl sm:text-4xl font-extrabold">
          Thanks for your application.
        </div>
      </div>
      <div className="font-light sm:text-xl tracking-wide my-2">
        Our VIP team is working on your application now and will be in touch
        shortly.
      </div>

      <div className="my-4 sm:my-6">
        <ReturnToMerchant merchant={merchant} asButton={true} />
      </div>
      <Support />
    </div>
  );
}
