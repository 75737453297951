import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Alert({
  icon,
  alertText,
  alertSubtext,
  containerClass,
}) {
  return (
    <div className={classNames('rounded-md', containerClass)}>
      <div className="flex items-start gap-2">
        <FontAwesomeIcon
          icon={icon}
          className="h-5 w-5 text-error hidden sm:inline p-2"
          aria-hidden="true"
        />
        <div className="flex flex-col sm:items-start">
          <h3 className={alertSubtext ? "font-semibold tracking-wide text-error mt-0.5" : "text-xs sm:text-sm text-error sm:mt-0.5"}>
            {alertText}
          </h3>
          <div className="text-sm text-error">{alertSubtext}</div>
        </div>
      </div>
    </div>
  );
}
