import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ApplyNowIntro from './init/intros/ApplyNowIntro';
import CartCanceled from './init/CartCanceled';
import Conversion from './init/Conversion';
import ExistingUser from './init/ExistingUser';
import ExistingUserToken from './init/ExistingUserToken';
import ExpiredLink from './init/ExpiredLink';
import InstoreIntro from './init/intros/InstoreIntro';
import NewBusinessDetails from './init/NewBusinessDetails';
import NewBusinessQualifications from './init/NewBusinessQualifications';
import OwnerPin from './init/OwnerPin';
import RechargeIntro from './init/intros/RechargeIntro';
import UserIsNotBorrower from './init/UserIsNotBorrower';
import UserLockout from './init/UserLockout';

import useSessionEntity from '../../hooks/useSessionEntity';

export default function Init({match}) {
  const merchant = useSessionEntity('merchant');

  if (merchant && merchant.isOnHold) return <Redirect to="/error" />

  return (
    <Switch>
      <Route path={`${match.path}/apply_now`} component={ApplyNowIntro} />
      <Route path={`${match.path}/conversion`} component={Conversion} />
      <Route path={`${match.path}/recharge_intro`} component={RechargeIntro} />
      <Route path={`${match.path}/instore_intro`} component={InstoreIntro} />
      <Route path={`${match.path}/new_business_qualifications`} component={NewBusinessQualifications} />
      <Route path={`${match.path}/new_business_details`} component={NewBusinessDetails} />
      <Route path={`${match.path}/existing_user`} component={ExistingUser} />
      <Route path={`${match.path}/existing_user_token/:type/:contact`} component={ExistingUserToken} />
      <Route path={`${match.path}/user_lockout`} component={UserLockout} />
      <Route path={`${match.path}/user_is_not_borrower`} component={UserIsNotBorrower} />
      <Route path={`${match.path}/cart_canceled`} component={CartCanceled} />
      <Route path={`${match.path}/expired_link`} component={ExpiredLink} />
      <Route path={`${match.path}/owner_pin`} component={OwnerPin} />
    </Switch>
  );
}
