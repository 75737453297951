import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Support from '../../shared/Support';
import useRouteStatus from '../../../hooks/useRouteStatus';
import useSession from '../../../hooks/useSession';

export default function Error({ location }) {
  const [session, sessionActions] = useSession();

  useRouteStatus(location);

  useEffect(() => {
    sessionActions.finish();
  }, []);

  return (
    <div role="main"  className="mx-6 sm:mx-auto">
      <div className="my-4 flex items-center">
        <span className="fa-layers fa-fw text-5xl">
          <FontAwesomeIcon
            icon="square"
            className="text-background-error !mx-0"
          />
          <FontAwesomeIcon
            icon={['far', 'triangle-exclamation']}
            className="text-error text-3xl !mx-1.5"
          />
        </span>
        <div className="text-xl sm:text-4xl font-extrabold ml-6">
          We're experiencing technical difficulties
        </div>
      </div>
      <div className="tracking-wide mb-6 font-light sm:text-lg">
        We are unable to process your{' '}
        {session.hasApplication() ? 'application' : 'order'} right now due to
        technical difficulties. Please try again later or contact us as soon as
        possible.
      </div>

      <Support />
    </div>
  );
}
