export const updateParent = (action, options, e) => {
  window.parent.postMessage(
    JSON.stringify({ action: action, options: options, type: 'modal' }),
    '*',
  );
};

export const setHeight = (height) => updateParent('height', height);

export const emitEvent = (user, event, shopping_cart) =>
  updateParent('creditkey-event', {
    user: user
      ? {
          email: user.email,
          phone: user.phone_number,
          first_name: user.first_name,
          last_name: user.last_name,
          shipping_address: shopping_cart
            ? shopping_cart.shipping_address
            : null,
          billing_address: shopping_cart ? shopping_cart.billing_address : null,
        }
      : null,
    event: event,
  });
