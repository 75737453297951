import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Loading from '../../shared/Loading';
import useSession from '../../../hooks/useSession';
import useSessionEntity from '../../../hooks/useSessionEntity';
import { getCompany } from '../../../actions/companies';

export default function Processing() {
  const [session, sessionActions] = useSession();
  const company = useSessionEntity('company');
  const dispatch = useDispatch();
  const maxRetryCount = 6;
  const retryCountDelay = 5000;

  const refreshCompanyStatus = (count) => {
    if (!company) {
      refreshCompanyStatus(0);
      return;
    }

    dispatch(getCompany(company.id)).then((res) => {
      const status = res.payload.data.attributes.underwriting_status;

      if (status === 'approved' || count >= maxRetryCount) {
        sessionActions.nextStep();
        return;
      } else {
        setTimeout(() => refreshCompanyStatus(count + 1), retryCountDelay);
      }
    });
  };

  useEffect(() => {
    refreshCompanyStatus(6);
  }, []);

  if (!company) {
    return <Loading className="w-14 h-14" containerClass="my-24 sm:my-0" />;
  }

  return (
    <div role="main"  className="mx-6 sm:mx-auto">
      <div className="text-xl sm:text-4xl font-extrabold mb-4">
        Processing
      </div>

      <div className="tracking-wide font-semibold text-lg mb-2">This shouldn't take long.</div>
      <div className="font-light sm:text-lg tracking-wide mb-6">
        We're just trying to make sure we correctly identify {company.name}.
      </div>

      <Loading className="w-14 h-14" />
    </div>
  );
}
