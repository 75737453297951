import { Record } from 'immutable';

export default class PaymentMethodRecord extends Record({
  id: undefined,
  user_id: null,
  account_holder: '',
  bank_account: '',
  bank_routing: '',
  payment_type: '',
  processor_data: undefined
}) {}
