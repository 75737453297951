import { Record } from 'immutable';

export default class MerchantRecord extends Record({
  id: null,
  platform: '',
  name: '',
  supports_standalone_csr: null,
  supports_pi4: false,
  supports_netx: false,
  supports_vcinstore: false,
  support_email: '',
  active_promo: {},
  public_key: '',
  promos: [],
  url: '',
  restricted_industry: null,
  slug: '',
  learn_more_link: '',
  default_checkout_method: null,
  status: '',
  integration_configuration: { show_close_navigation: true },
  bank_bypass: false
}) {
  get isOnHold() {
    return this.status === 'hold';
  }
}
