import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Error from '../../shared/Error';
import Loading from '../../shared/Loading';
import ProgressBars from '../../shared/ProgressBars';
import useSessionEntity from '../../../hooks/useSessionEntity';
import useValidateRoute from '../../../hooks/useValidateRoute';
import { asyncActions } from '../../../actions/general';
import { getRoute, getSession, loading } from '../../../actions/sessions';
import { getShoppingCart } from '../../../actions/shopping_carts';
import { submitApplication } from '../../../actions/applications';

export default function ProcessApplication() {
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.sessions.get('loading'));
  const session = useSelector((state) => state.sessions.get('session'));
  const application = useSessionEntity('application');
  const cart = useSessionEntity('shopping_cart');

  const isValidRoute = useValidateRoute({ apply: true, company: true });

  useEffect(() => {
    if (isValidRoute && application) {
      dispatch(loading(true));
      dispatch(
        asyncActions(
          submitApplication(application),
          getSession({
            cart: false,
            merchant: false,
            prequal: false,
            user: false,
          }),
          getRoute(),
          cart && getShoppingCart(session.depId('shopping_cart')),
        ),
      ).then(() => dispatch(loading(false)));
    }
  }, []);

  return (
    <div role="main" className="mx-6 sm:mx-auto min-h-[50vh]">
      <ProgressBars />
      <div className="mt-4 mb-1 sm:mb-4">
        <div className="text-xl sm:text-4xl font-extrabold">
          We're processing your information
        </div>
      </div>
      <div className="tracking-wide mb-6 font-light text-sm sm:text-lg">
        Remember, applying will not affect your personal credit.
      </div>

      <Error />

      {isLoading && <Loading className="h-14 h-14" containerClass="my-14" />}
    </div>
  );
}
