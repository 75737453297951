import React, { useState } from 'react';
import Select from 'react-select';
import { ErrorMessage } from 'formik';

import useInputs from '../../../hooks/useInputs';
import { formStyles } from '../../../lib/constants/SiteVariables';

export default function StickyIndustryTypeAhead(props) {
  const [selectedOption, setSelectedOption] = useState();

  const { id, placeholder, setFieldValue, values } = props;
  const { hasFieldError } = useInputs(props);

  const classNames = {
    control: () =>
      `text-xs sm:text-sm pt-1 !rounded-lg text-left border ${
        hasFieldError && '!border-error'
      }`,
    input: () => '!mt-2.5 !sm:mt-5 pl-1',
    menuList: () => 'text-left text-xs sm:text-sm',
    placeholder: () => 'pt-0 sm:pt-2 pb-1 pl-0.5 text-left text-xs sm:text-sm',
    dropdownIndicator: () => '!text-secondary-gray w-8',
  };

  const onChangeSelectedOption = (e) => {
    const selectedOption = e.id;
    setFieldValue('industry', e.id);
    setSelectedOption(selectedOption);
  };

  return (
    <div
      className="sticky-type-ahead relative w-full"
      role="stickyindustryselect"
    >
      <Select
        id={id}
        name={id}
        value={selectedOption?.id}
        onChange={onChangeSelectedOption}
        options={props?.options}
        components={{
          IndicatorSeparator: () => null
        }}
        placeholder="Begin search by typing..."
        classNames={classNames}
        className="async-type-ahead ring-offset-transparent text-sm peer"
        defaultInputValue={
          props?.options?.find((o) => o.id === values?.industry)?.label
        }
      />
      <label
        htmlFor={id}
        className="absolute text-xs sm:text-sm text-tertiary-gray transition-all duration-300 origin-left left-3.5 top-1.5 transform scale-75"
      >
        {placeholder}
      </label>
      <ErrorMessage
        name={id}
        component="div"
        className={formStyles.validation}
      />
    </div>
  );
}
