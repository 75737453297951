import { useSelector } from 'react-redux';

function useSessionEntity(type) {
  const entity = useSelector((state) => state.entities.get(type));

  if (!entity) throw `Invalid entity ${type}`;

  const session = useSelector((state) => state.sessions.get('session'));
  const id = session.depId ? session.depId(type) : null;

  return useSelector(state => state.entities.get(type).get(id));
}

export default useSessionEntity;
