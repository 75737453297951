import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Support from '../../shared/Support';

export default function UserLockout() {
  return (
    <div id="content" role="main"  className="mx-6 sm:mx-auto">
      <div className="my-4 flex items-center">
        <span className="fa-layers fa-fw text-5xl">
          <FontAwesomeIcon
            icon="square"
            className="text-secondary-blue !mx-0"
          />
          <FontAwesomeIcon
            icon={['far', 'triangle-exclamation']}
            className="text-primary-blue text-3xl !mx-1.5"
          />
        </span>
        <div className="text-xl sm:text-4xl font-extrabold ml-3">
          Account Locked Out
        </div>
      </div>
      <p className="font-light sm:text-lg tracking-wide my-3 sm:my-6">
        Due to too many failed login attempts, we've blocked access to your
        account. Please contact Credit Key support to unlock your account.
      </p>

      <div className="mt-6 sm:mt-12">
        <Support />
      </div>
    </div>
  );
}
