import React from 'react';
import Cards from 'react-credit-cards';
import Support from './Support';
import 'react-credit-cards/es/styles-compiled.css';

export default function CreditCardDisplay({ cvc, expiration, name, number }) {
  return (
    <div data-testid="virtual-card-wrapper">
      <div className="flex flex-col gap-3 my-3">
        <Cards
          cvc={cvc}
          expiry={expiration}
          name={name}
          number={number}
          focused="number"
        />
        <Cards
          cvc={cvc}
          expiry={expiration}
          name={name}
          number={number}
          focused="cvc"
        />
      </div>
      <div className="font-semibold tracking-wide">
        This card is valid for this single purchase. Soon you'll be able to use
        Credit Key virtual cards for multiple purchases.
        <Support />
      </div>
    </div>
  );
}
