import { fromJS } from 'immutable';
import * as ActionTypes from '../lib/constants/ActionTypes';

export const generalState = fromJS({
  debugging: {
    key: '',
    workflow: '',
    action: '',
    uuid: null
  },
  errors: [],
  rethrow: false
});

function setState(state, newState) {
  return state.merge(newState);
}

export default function general(state = generalState, action) {
  switch(action.type) {
    case ActionTypes.CLEAR_ERRORS:
      return setState(state, generalState);

    case ActionTypes.SET_DEBUG:
      return setState(state, state.setIn(['debugging', action.payload.key], action.payload.value));

    case ActionTypes.SET_ERROR:
      return setState(state, state.set('errors', state.get('errors').push(action.payload)));

    case ActionTypes.TOGGLE_RETHROW:
      return setState(state, state.set('rethrow', !state.get('rethrow')));

    default:
      return state;
  }
}
