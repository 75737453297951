import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Error from '../../shared/Error';
import ProgressBars from '../../shared/ProgressBars';
import useRouteStatus from '../../../hooks/useRouteStatus';
import useSession from '../../../hooks/useSession';
import useSessionEntity from '../../../hooks/useSessionEntity';

// Reasons
import CartPend from './pending/CartPend';
import Default from './pending/Default';
import Fraud from './pending/Fraud';
import FrozenFile from './pending/FrozenFile';
import NewBusiness from './pending/NewBusiness';
import Support from '../../shared/Support';

export default function Pending({ location }) {
  const [session, sessionActions] = useSession();
  const application = useSessionEntity('application');
  const cart = useSessionEntity('shopping_cart');
  const user = useSessionEntity('user');
  const [reason, setReason] = useState();
  location = location || { pathname: '' };

  useRouteStatus(location);

  useEffect(() => {
    sessionActions.finish();
  }, []);

  useEffect(() => {
    if (application?.reasons) {
      if (application.reasons.includes('new_business'))
        setReason('new_business');
      if (application.reasons.includes('frozen_file')) setReason('frozen_file');
      if (application.reasons.includes('fraud_alert')) setReason('fraud_alert');
    }
  }, [application]);

  useEffect(() => {
    if (cart?.reasons) {
      if (cart.reasons.includes('address_mismatch')) setReason('cart');
      if (cart.reasons.some((r) => /bank/i.test(r))) setReason('cart');
    }
  }, [cart]);

  return (
    <div role="main"  className="mx-6 sm:mx-auto">
      <ProgressBars />
      <div className="my-4 flex items-center">
        <span className="fa-layers fa-fw text-5xl">
          <FontAwesomeIcon
            icon="square"
            className="text-secondary-blue !mx-0"
          />
          <FontAwesomeIcon
            icon={['far', 'inbox']}
            className="text-primary-blue text-3xl !mx-1.5"
          />
        </span>
        <div className="text-xl sm:text-4xl font-extrabold">Almost There!</div>
      </div>

      {reason === undefined && <Default />}
      {reason === 'new_business' &&  <NewBusiness user={user} /> }
      {reason === 'fraud_alert' && <Fraud user={user} />}
      {reason === 'frozen_file' && <FrozenFile user={user} />}
      {reason === 'cart' && <CartPend />}

      <Error />
      <div className="mt-6 sm:mt-12">
        <Support />
      </div>
    </div>
  );
}
