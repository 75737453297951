import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { formatCurrency } from '../../lib/utils/formatters';

export default function TermItem({
  parentSelectedTerm,
  setParentTerm,
  term,
  children,
}) {
  const [selectedTerm, setSelectedTerm] = useState(parentSelectedTerm);

  useEffect(() => {
    if (setParentTerm) setParentTerm(selectedTerm);
  }, [selectedTerm]);

  const termOnClick = (term) => {
    setSelectedTerm({
      term: parseInt(term.get('term')),
      term_period: term.get('term_period'),
      payment: parseFloat(term.get('monthly_payment')),
    });
  };

  return (
    <div
      className={classNames(
        'mb-1 p-3 border rounded-md shadow my-1 sm:my-2.5 mx-auto w-full sm:h-20 flex flex-col justify-center',
        {
          'cursor-pointer': term.get('available'),
          'border-secondary-gray': !term.get('available'),
          'border-[3px] border-success':
            term.get('available') &&
            parentSelectedTerm &&
            term.get('term') === parentSelectedTerm.term,
        },
      )}
      key={term.get('term')}
      id={'term-' + term.get('term')}
      data-term={term.get('term')}
      data-payment={formatCurrency(term.get('monthly_payment'))}
      onClick={() => termOnClick(term)}
    >
      {children}
    </div>
  );
}
