import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';

import Error from '../../shared/Error';
import Loading from '../../shared/Loading';
import PrimaryButton from '../../shared/buttons/PrimaryButton';
import useRouteStatus from '../../../hooks/useRouteStatus';
import useSessionEntity from '../../../hooks/useSessionEntity';
import { asyncActions } from '../../../actions/general';
import { borrowerPortalUrl } from '../../../lib/constants/SiteVariables';
import { formatCurrency } from '../../../lib/utils/formatters';
import { getCreditLineAvailable } from '../../../actions/companies';
import { getRoute, loading } from '../../../actions/sessions';

export default function HasCreditChanges({ location }) {
  const dispatch = useDispatch();

  const application = useSessionEntity('application');
  const company = useSessionEntity('company');

  const [tclAvailable, setTclAvailable] = useState(0);
  const NextButton = () => {
    return (
      <PrimaryButton
        buttonText="Next"
        onClick={nextScreen}
        buttonColor="bg-primary-blue"
        hoverClass="hover:bg-primary-blue-hover"
      />
    );
  };

  const nextScreen = (e) => {
    e.preventDefault();

    dispatch(loading(true));
    dispatch(asyncActions(getRoute())).then((res) => dispatch(loading(false)));
  };

  useEffect(() => {
    if (company) {
      dispatch(asyncActions(getCreditLineAvailable(company.id))).then((res) =>
        setTclAvailable(res.attributes.data),
      );
    }
  }, [company, dispatch]);

  useRouteStatus(location);

  if (!application || !company)
    return <Loading className="w-14 h-14" containerClass="my-24 sm:my-0" />;

  if (
    parseFloat(application.previous_tcl) <
    parseFloat(company.credit_line.get('amount'))
  ) {
    return (
      <div id="content" role="main" className="mx-6 sm:mx-auto">
        <div className="my-4 flex items-center">
          <span className="fa-layers fa-fw text-5xl">
            <FontAwesomeIcon
              icon="square"
              className="text-secondary-blue !mx-0"
            />
            <FontAwesomeIcon
              icon={['far', 'thumbs-up']}
              className="text-primary-blue text-3xl !mx-1.5"
            />
          </span>
          <div className="text-xl sm:text-4xl font-extrabold">Good News!</div>
        </div>
        <h2 className="font-semibold text-lg tracking-wide mb-1">
          You’ve earned an increase in your Credit Key credit line.
        </h2>

        <Error />

        <p className="font-light sm:text-lg tracking-wide mb-4">
          Your credit limit of {formatCurrency(application?.previous_tcl)} has
          been increased to{' '}
          <span className="font-bold text-success">
            {formatCurrency(company.credit_line.get('amount'))}
          </span>
          .
        </p>
        <p className="font-semibold sm:text-lg tracking-wide mb-6">
          You currently have {formatCurrency(tclAvailable)} available to spend.
        </p>
        <NextButton />
      </div>
    );
  } else {
    return (
      <div role="main"  className="mx-6 sm:mx-auto">
        <div className="my-4 flex items-center">
          <span className="fa-layers fa-fw text-5xl">
            <FontAwesomeIcon
              icon="square"
              className="text-secondary-blue !mx-0"
            />
            <FontAwesomeIcon
              icon={['far', 'thumbs-up']}
              className="text-primary-blue text-3xl !mx-1.5"
            />
          </span>
          <div className="text-xl sm:text-4xl font-extrabold">
            Quick heads-up
          </div>
        </div>
        <p className="font-light sm:text-lg tracking-wide mb-4">
          There's been an across the board update to credit limits due to market
          conditions and necessary underwriting changes.
        </p>
        <p className="font-semibold sm:text-lg tracking-wide mb-2 sm:mb-1">
          Your new credit limit is{' '}
          {formatCurrency(company.credit_line.get('amount'))}, with{' '}
          {tclAvailable < 0 ? '$0' : formatCurrency(tclAvailable)} remaining.
        </p>
        <p className="font-light sm:text-lg tracking-wide mb-4">
          You can request a line increase by submitting 3 months' bank
          statements
          <a
            href="https://www.creditkey.com/credit-key-increase-request"
            target="_blank"
            className="font-semibold text-primary-blue cursor-pointer underline ml-1"
          >
            here
          </a>
          .
        </p>

        <Error />

        {tclAvailable < 0 && (
          <>
            <p className="font-semibold sm:text-lg tracking-wide mb-1">
              You are currently over your limit.
            </p>
            <p className="font-light sm:text-lg tracking-wide mb-4">
              Please log into your
              <a
                href={borrowerPortalUrl}
                target="_blank"
                className="mx-1 underline text-primary-blue"
              >
                borrower dashboard
              </a>
              to make a payment at your earliest convenience.
            </p>
          </>
        )}
        <NextButton />
      </div>
    );
  }
}
