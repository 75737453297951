import React from 'react';
import { Route, Switch } from 'react-router-dom';
import RerunCredit from './reapply/RerunCredit';
import HasCreditChanges from './reapply/HasCreditChanges';

export default function Reapply({match}) {
  return (
    <Switch>
      <Route path={`${match.path}/rerun_credit`} component={RerunCredit} />
      <Route path={`${match.path}/has_credit_changes`} component={HasCreditChanges} />
    </Switch>
  );
}
