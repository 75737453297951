import React from 'react';

import PrimaryButton from '../buttons/PrimaryButton';
import OutlineButton from '../buttons/OutlineButton';
import { ModalWrapper, ModalFooter } from '.';
import { daysAsMonths, formatCurrency } from '../../../lib/utils/formatters';

export default function LargePaymentModal({
  isModalActive,
  toggleModal,
  cart,
  merchant,
  setLargeSinglePayment,
}) {
  return (
    <ModalWrapper isModalActive={isModalActive} toggleModal={toggleModal}>
      <div className="px-2 sm:p-3" role={LargePaymentModal.role}>
        <header className="text-lg sm:text-2xl font-semibold text-primary-blue mb-4">
          Confirm Order Amount
        </header>
        <section>
          <div className="flex flex-col gap-4">
            <p className="font-light text-sm sm:text-lg">
              Did you intend to pay for your entire
              <span className="font-semibold text-sm sm:text-lg mx-1">
                {formatCurrency(parseFloat(cart.grand_total)) + ' '}
                order
              </span>
              in one payment, due in
              <span className="font-semibold text-sm sm:text-lg mx-1">
                {daysAsMonths(merchant.active_promo.get('max_net_payment') + 1)}
              </span>
              after your order ships?
            </p>
            <p className="font-light text-sm sm:text-lg">
              If not, you can go back and select a longer term.
            </p>
            <div className="flex flex-col sm:flex-row justify-evenly item-center sm:gap-4">
              <PrimaryButton
                buttonText="Continue"
                buttonColor="bg-primary-blue"
                hoverClass="hover:bg-primary-blue-hover"
                onClick={() => {
                  setLargeSinglePayment(true);
                  toggleModal();
                }}
              />
              <OutlineButton
                buttonText="Go Back"
                buttonColor="border-primary-blue text-primary-blue hover:text-primary-blue-hover hover:border-primary-blue-hover"
                onClick={toggleModal}
              />
            </div>
          </div>
        </section>
      </div>
      <ModalFooter className="text-xs xxs:text-sm mt-4 sm:mt-0" />
    </ModalWrapper>
  );
}

LargePaymentModal.role = 'large-payment-modal-component';
