import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Loading from '../../shared/Loading';
import Support from '../../shared/Support';
import UserVerification from '../../shared/UserVerification';
import { loading } from '../../../actions/sessions';
import { tokenIsExpired } from '../../../actions/authentication';

export default function ExistingUser({ defaultValue, onSuccessPath }) {
  const publicKey = useSelector((state) => state.sessions.get('public_key'));
  const token = useSelector((state) => state.authentication.get('token'));
  const isError = useSelector((state) => state.sessions.get('errors'));
  const isLoading = useSelector((state) => state.sessions.get('loading'));

  if (isError && isError.count() > 0 && !tokenIsExpired(token)) {
    return <Redirect to="/invalid" />;
  }

  if (!publicKey) {
    return (
      <div role="main"  className="mx-6 sm:mx-auto">
        <div className="my-4 flex items-center">
          <span className="fa-layers fa-fw text-5xl">
            <FontAwesomeIcon
              icon="square"
              className="text-background-error !mx-0"
            />
            <FontAwesomeIcon
              icon={['far', 'circle-xmark']}
              className="text-error text-3xl !mx-1.5"
            />
          </span>
          <div className="text-xl sm:text-4xl font-extrabold">404: Uh Oh!</div>
        </div>
        <div className="text-lg sm:text-3xl font-semibold">
          You have reached this page in error.
        </div>
        <p className="font-light sm:text-lg tracking-wide mt-2 mb-6">
          This site is for processing credit applications and business purchases
          with Credit Key.
        </p>
        <div className="mt-6 sm:mt-12">
          <Support />
        </div>
      </div>
    );
  }

  if (isLoading) return <Loading className="w-14 h-14" containerClass="my-24 sm:my-0" />;

  return (
    <div id="content" role="main"  className="mx-6 sm:mx-auto">
      <div className="my-4 flex items-center">
        <span className="fa-layers fa-fw text-5xl">
          <FontAwesomeIcon
            icon="square"
            className="text-secondary-blue !mx-0"
          />
          <FontAwesomeIcon
            icon={['far', 'hand-wave']}
            className="text-primary-blue text-3xl !mx-1.5"
          />
        </span>
        <div className="text-xl sm:text-4xl font-extrabold">
          Welcome to Credit Key
        </div>
      </div>
      <div className="font-light sm:text-lg tracking-wide mb-6">
        <div>
          {tokenIsExpired(token) && (
            <p>Your session has expired, please sign in to continue.</p>
          )}
          {!tokenIsExpired(token) && (
            <p>Please sign in. We'll send a 6-digit code to you.</p>
          )}
        </div>
      </div>
      <UserVerification
        defaultValue=""
        onSuccessPath="existing_user_token"
        onLoading={loading}
        isLoading={isLoading}
      />
      <div className="mt-6 sm:mt-12">
        <Support />
      </div>
    </div>
  );
}
