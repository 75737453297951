import React from 'react';
import Logo from '../Logo';

export default function LogoHeader({ merchant }) {
  return (
    <nav className="h-12 sm:h-16 py-4 sm:py-0 px-2 md:px-16 shadow-none flex justify-between items-center rounded-none border-solid border-b-[3px] border-border-gray">
      <div className="w-full flex items-center justify-center">
        <div className="flex items-center">
          <Logo merchant={merchant} />
        </div>
      </div>
    </nav>
  );
}
