import React, { useEffect } from 'react';

import Default from './approved/Default';
import Recharge from './approved/Recharge';
import useRouteStatus from '../../../hooks/useRouteStatus';
import useSession from '../../../hooks/useSession';
import useSessionEntity from '../../../hooks/useSessionEntity';

import { emitEvent } from '../../../lib/utils/modal';

export default function Approved({ location }) {
  const [session, sessionActions] = useSession();
  const company = useSessionEntity('company');
  const user = useSessionEntity('user');
  const cart = useSessionEntity('shopping_cart');
  const merchant = useSessionEntity('merchant');
  const isRecharge = session.hasCustomFlag('recharge') || session.hasCustomFlag('instore');

  useRouteStatus(location);

  useEffect(() => {
    emitEvent(user, {
      path: location.pathname,
      credit_line: company.credit_line
    }, cart);

    sessionActions.finish();
  }, []);

  return (
    <div role="main"  className="mx-6 sm:mx-auto">
      {isRecharge && <Recharge merchant={merchant} session={session} />}
      {!isRecharge && <Default merchant={merchant} />}
    </div>
  );
}
