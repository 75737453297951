import React, { useState, useContext, createContext } from "react";
import { fromJS } from 'immutable';
import Network from '../lib/utils/network';

import { getUserToken, tokenIsExpired } from "../actions/authentication";

// Concepts based off of https://usehooks.com/useAuth/

const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const api = Network();

  const path = resource => {
    if (!resource) resource = '';
    return { resource: 'api/users/' + resource };
  };

  const [userToken, setUserToken] = useState();
  const isAuthenticated = () => (userToken && getUserToken() && !tokenIsExpired(userToken));

  //FOR FUTURE USE: To be used when we decide how to re-hydrate and/or re-route user upon hard refresh
  const tokenInfo = () => {
    return (dispatch, getState, api) => {
      return api.get({ resource: 'oauth/token/info' })
        .then(res => setUserToken(fromJS(res.token)))
        .catch(err => setUserToken(null));
    };
  };

  // send request to API to verify contact type (email/phone) and send login token
  const verify = (type, contact, send_to_email = false) => {
    return (dispatch, getState) => {
      return api.post(path('verify'), {
        type: type,
        contact: contact,
        send_to_email: send_to_email
      });
    };
  };

  const verifyToken = (type, contact, token) => {
    return (dispatch, getState) => {
      return api.post(path('verified'), {
        type: type,
        contact: contact,
        verification_code: token
      });
    };
  };

  return {
    userToken,
    setUserToken,
    verify,
    verifyToken,
    isAuthenticated
  };
}
