import React from 'react';

export default function Default() {
  return (
    <div>
      <p className="font-light sm:text-lg tracking-wide mb-6">
        It looks like we'll need a bit more information to approve your account.
      </p>

      <p className="font-semibold sm:text-lg tracking-wide mb-6">
        Check your email for next steps.
      </p>

      <p className="font-light sm:text-lg tracking-wide mb-6">
        If we're unable to reach you after 10 days, we'll consider your
        application withdrawn.
      </p>
    </div>
  );
}
