import React from 'react';

import { Route, Switch } from 'react-router-dom';
import { Provider, ErrorBoundary } from '@rollbar/react';
import { rollbarConfig } from './actions/general';

import Index from './components/Index';
import Session from './components/Session';
import Init from './components/sessions/Init';
import Reapply from './components/sessions/Reapply';
import Apply from './components/sessions/Apply';
import Purchase from './components/sessions/Purchase';
import Finish from './components/sessions/Finish';
import ErrorPage from './components/ErrorPage';
import Invalid from './components/Invalid';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowLeft,
  faSquare,
  faTimes,
  faCircleCheck as fasCircleCheck,
} from '@fortawesome/free-solid-svg-icons';

import {
  faCircleQuestion,
  faInfoCircle,
} from '@fortawesome/pro-light-svg-icons';

import {
  faCircleCheck,
  faHandWave,
  faInbox,
  faHeartCrack,
  faCircleXmark,
  faRepeat,
  faThumbsUp,
  faQuestionCircle,
  faLockAlt,
  faTriangleExclamation,
  faSpinnerThird,
  faArrowRight,
} from '@fortawesome/pro-regular-svg-icons';

import { ProvideAuth } from './hooks/useAuth';

// Add icons to FA library for global usage
library.add(
  faCircleQuestion,
  faCircleCheck,
  faInfoCircle,
  faQuestionCircle,
  faTimes,
  faLockAlt,
  faThumbsUp,
  faRepeat,
  faCircleXmark,
  faArrowLeft,
  faTriangleExclamation,
  faSquare,
  faHandWave,
  faInbox,
  faHeartCrack,
  fasCircleCheck,
  faSpinnerThird,
  faArrowRight,
);

export const fromLearnMore = new URLSearchParams(window.location.search).get(
  'learn-more',
);

export const workflows = [
  {
    path: '/init',
    component: Init,
  },
  {
    path: '/reapply',
    component: Reapply,
  },
  {
    path: '/apply',
    component: Apply,
  },
  {
    path: '/purchase',
    component: Purchase,
  },
  {
    path: '/finish',
    component: Finish,
  },
  {
    path: '/invalid',
    component: Invalid,
  },
  {
    path: '/error',
    component: ErrorPage,
  },
];

export default function App() {
  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <ProvideAuth>
          <div className="App flex flex-col overflow-hidden text-primary">
            <main className="flex-grow overflow-auto">
              <Switch>
                <Route exact path="/" component={Index} />
                <Route
                  path={workflows.map((w) => w.path)}
                  component={Session}
                />
                <Route
                  path="/:public_key/:prequalification_id?"
                  component={Session}
                />
              </Switch>
            </main>
          </div>
        </ProvideAuth>
      </ErrorBoundary>
    </Provider>
  );
}
