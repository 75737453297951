import React from 'react';

import spinner from '../../lib/images/spinner.svg';

export default function Loading({ className, containerClass }) {
  return (
    <div className={`flex justify-center ${containerClass}`} role="loading">
      <img src={spinner} className={`animate-spin ${className}`} />
    </div>
  );
}
