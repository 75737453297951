import { fromJS } from 'immutable';
import * as ActionTypes from '../lib/constants/ActionTypes';

export const authState = fromJS({
  loading: false,
  token: null,
});

function setState(state, newState) {
  return state.merge(newState);
}

export default function authentication(state = authState, action) {
  switch(action.type) {
    case ActionTypes.TOKEN_LOADING:
      return setState(state, state.set('loading', action.payload));

    case ActionTypes.SET_TOKEN:
      return setState(state, state.set('token', action.payload));

    default:
      return state;
  }
}
