/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import {
  aBToken,
  aBPolyFill,
  aBSource,
} from '../../lib/constants/SiteVariables';

const commonStyles = `
      border-color: #abb4be !important;
      border-radius: 6px;
      border: 1px solid transparent;
      color: #182b3e !important;
      color: #363636;
      font-family: "proxima-nova", Helvetica, sans-serif;
      font-size: 1rem;
      height: 1.75em;
      line-height: 1.5;
      padding-bottom: calc(0.5em - 1px);
      padding-left: calc(0.75em - 1px);
      padding-right: calc(0.75em - 1px);
      padding-top: calc(0.5em - 1px);
      margin-bottom: 10px;
    `;

const styles = {
  container: 'height: 750px !important',
  card: commonStyles + 'width: 93%;',
  expiryMonth: commonStyles + 'width: 8%;',
  expiryYear: commonStyles + 'width: 8%;',
  cvv2: commonStyles + 'width: 20%;',
  avsZip: commonStyles + 'width: 40%;',
};

function loadScript(src) {
  return new Promise(function (resolve, reject) {
    const s = document.createElement('script');
    let r = false;
    s.type = 'text/javascript';
    s.src = src;
    s.async = true;
    s.onerror = function (err) {
      reject(err, s);
    };
    s.onload = s.onreadystatechange = function () {
      if (!r && (!this.readyState || this.readyState == 'complete')) {
        r = true;
        resolve();
      }
    };
    const t = document.getElementsByTagName('script')[0];
    t.parentElement.insertBefore(s, t);
  });
}

function AcceptBlueHostedForm({
  setFormInteractedWith,
  setCardForm,
  cardForm,
  loadCardForm,
  setCardFormErrors,
}) {
  const history = useHistory();

  const acceptBlueEvent = (event) => {
    setCardFormErrors([]);
    if (event.error) setCardFormErrors([event.error]);
    setFormInteractedWith(true);
  };

  const waitForForm = async () => {
    if (window.HostedTokenization && cardForm) return;

    if (window.HostedTokenization && !cardForm) {
      const form = new window.HostedTokenization(aBToken).create('card-form');
      setCardForm(form);

      form.mount('#accept-blue').on('change', acceptBlueEvent);
      form.setStyles(styles);
    }
  };

  useEffect(() => {
    if (!loadCardForm) return;

    loadScript(aBPolyFill)
      .then(() => loadScript(aBSource))
      .then(() => waitForForm())
      .catch(() => {
        history.push('/error');
      });
  }, [loadCardForm]);

  return (
    <div role={AcceptBlueHostedForm.role}>
      <div id="accept-blue"></div>
    </div>
  );
}

AcceptBlueHostedForm.role = 'accept-blue-hosted-form-component';

export default AcceptBlueHostedForm;
