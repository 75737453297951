import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
});

const response = res => res.then(r => r.data);

/**
 * @function Network
 * @description Factory function to create a object that can send
 * requests to a specific resource on the server.
 * @param {string} resource The resource used for config
 */
const Network = (resource, token) => {
  let buildURL = ({ params, id, resource } = {}) => {
    let parameters = [];

    if (token) {
      api.defaults.headers.common['Authorization'] = `Bearer ${token.access_token}`
    }

    if (resource) parameters = parameters.concat([resource]);
    if (id) parameters = parameters.concat([id]);

    if (params) {
      return parameters.join('/') + params;
    }
    return parameters.join('/');
  };

  return {

    /**
     * @function post
     * @description Make a POST request.
     * @param {string} path
     * @param {object} body
     * @param {object} options
     * @returns {promise}
     */
    post: (path, body) => {
      return response(api.post(buildURL(path), JSON.stringify(body)));
    },

    /**
     * @function post
     * @description Make a GET request.
     * @param {string} path
     * @param {object} options
     * @returns {promise}
     */
    get: path => {
      return response(api.get(buildURL(path)));
    },

    /**
     * @function edit
     * @description Make a PUT request.
     * @param {string} path
     * @param {object} body
     * @param {object} options
     * @returns {promise}
     */
    edit: (path, body) => {
      return response(api.put(buildURL(path), JSON.stringify(body)));
    },

    /**
     * @function patch
     * @description Make a PATCH request.
     * @param {string} path
     * @param {object} body
     * @param {object} options
     * @returns {promise}
     */
    patch: (path, body) => {
      return response(api.patch(buildURL(path), JSON.stringify(body)));
    },

    /**
     * @function delete
     * @description Make a DELETE request.
     * @param {string} path
     * @param {object} options
     * @returns {promise}
     */
    delete: (path, body) => {
      return response(api.delete(buildURL(path), { data: JSON.stringify(body) }));
    },

    ping: () => api.get(buildURL())
  };
};

export default Network;
