import React, { useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import Error from '../../shared/Error';
import PrimaryButton from '../../shared/buttons/PrimaryButton';
import ProgressBars from '../../shared/ProgressBars';
import useRouteStatus from '../../../hooks/useRouteStatus';
import useSessionEntity from '../../../hooks/useSessionEntity';
import useValidateRoute from '../../../hooks/useValidateRoute';
import {
  restrictedTextYes,
  restrictedTextNo,
} from '../../../lib/utils/merchant_restricted_text';
import { asyncActions } from '../../../actions/general';
import { getRoute, loading } from '../../../actions/sessions';
import { updateApplication } from '../../../actions/applications';

export default function StateRestrictedProducts({ location }) {
  const dispatch = useDispatch();
  const application = useSessionEntity('application');
  const merchant = useSessionEntity('merchant');
  const [restrictedProducts, sellsRestrictedProducts] = useState(false);

  const isLoading = useSelector((state) => state.sessions.get('loading'));

  useRouteStatus(location);

  if (!useValidateRoute({ apply: true, company: true })) return <></>;

  const save = () => {
    dispatch(loading(true));
    dispatch(
      asyncActions(
        updateApplication(
          application.set('sells_restricted_products', restrictedProducts),
        ),
        getRoute(),
      ),
    ).then((res) => dispatch(loading(false)));
  };

  return (
    <div role="main"  className="mx-6 sm:mx-auto">
      <ProgressBars />
      <div className="mt-4 mb-1 sm:mb-4">
        <div className="text-xl sm:text-4xl font-extrabold">Please Confirm</div>
      </div>
      <Error />

      <div className="flex flex-col items-center gap-4 w-full my-6">
        <div
          className={classNames(
            'flex items-center border rounded-lg py-3 px-6 w-full group',
            !sellsRestrictedProducts
              ? 'border-primary-blue'
              : 'primary-border-gray ',
          )}
          onClick={() => sellsRestrictedProducts(false)}
        >
          <input
            type="radio"
            className="h-4 w-4 sm:h-5 sm:w-5 border-tertiary-gray group-focus:ring-primary-blue mt-1"
            id="sells_restricted_products_no"
            name="sells_restricted_products_no"
            onChange={() => sellsRestrictedProducts(false)}
            value={restrictedProducts}
            checked={!restrictedProducts}
          />
          <label
            htmlFor="sells_restricted_products_no"
            className="ml-4 block text-sm sm:text-lg font-light tracking-wide"
            onClick={() => sellsRestrictedProducts(false)}
            dangerouslySetInnerHTML={{
              __html: `${application.company_name} ${restrictedTextNo(
                merchant,
              )}`,
            }}
          />
        </div>

        <div
          className={classNames(
            'flex items-center border rounded-lg py-3 px-6 w-full group',
            sellsRestrictedProducts
              ? 'border-primary-blue'
              : 'primary-border-gray ',
          )}
          onClick={() => sellsRestrictedProducts(true)}
        >
          <input
            type="radio"
            className="h-4 w-4 sm:h-5 sm:w-5 border-tertiary-gray group-focus:ring-primary-blue mt-1"
            id="sells_restricted_products_yes"
            name="sells_restricted_products_yes"
            onChange={() => sellsRestrictedProducts(true)}
            value={restrictedProducts}
            checked={restrictedProducts}
          />
          <label
            htmlFor="sells_restricted_products_yes"
            className="ml-4 block text-sm sm:text-lg font-light tracking-wide"
            onClick={() => sellsRestrictedProducts(true)}
            dangerouslySetInnerHTML={{
              __html: `${application.company_name} ${restrictedTextYes(
                merchant,
              )}`,
            }}
          />
        </div>
      </div>

      <div>
        <p className="text-sm text-secondary-gray mb-3 sm:mb-6">
          I hereby certify, to the best of my knowledge, that the information
          provided above is complete and correct
        </p>
        <PrimaryButton
          buttonText="Next"
          buttonColor='bg-primary-blue'
          hoverClass='hover:bg-primary-blue-hover'
          disabled={isLoading}
          isLoading={isLoading}
          onClick={save}
        />
      </div>
    </div>
  );
}
