import React from 'react';

export default function NewBusiness({ user }) {
  return (
    <div>
      <div className="font-semibold text-lg tracking-wide mb-4">
        Hi {user?.first_name},
      </div>

      <div className="font-light text-sm sm:text-lg tracking-wide leading-6 mb-3 sm:mb-6">
        Thank you for your interest with Credit Key. It looks like your business
        was recently incorporated. Credit Key loves helping new businesses, but
        we’ll need to verify a few things first.
      </div>

      <div className="font-light text-sm sm:text-lg tracking-wide leading-6 mb-3 sm:mb-6">
        Our team will reach out as soon as possible. We apologize in advance for
        any inconvenience. If you want to specify a time/day for us to call you,
        please feel free to reach out to Credit Key Support in the chat window
        below, or by <a href="mailto: support@creditkey.com">email</a>.
      </div>

      <div className="font-light text-sm sm:text-lg tracking-wide leading-6 mb-3 sm:mb-6">
        If we’re unable to reach you within 10 business days, we’ll consider
        your credit application withdrawn.
      </div>
    </div>
  );
}
