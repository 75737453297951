import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import useSessionEntity from '../../../hooks/useSessionEntity';
import useRouteStatus from '../../../hooks/useRouteStatus';

import BankCredentials from './BankCredentials';
import CreditCardCredentials from './CreditCardCredentials';
import Loading from '../../shared/Loading';

export default function AddPaymentMethod() {
  const location = useLocation();
  const merchant = useSessionEntity('merchant');
  const session = useSelector((state) => state.sessions.get('session'));

  useRouteStatus(location);

  if (!merchant) return <Loading className="w-14 h-14" />;

  return merchant.bank_bypass && session?.session_type !== 'instore' ? (
    <CreditCardCredentials />
  ) : (
    <BankCredentials />
  );
}
