import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Support from './shared/Support';

export default function InvalidCKApplication() {
  return (
    <div role="main" className="mx-6 sm:mx-auto">
      <div className="my-4 flex items-center">
        <span className="fa-layers fa-fw text-5xl">
          <FontAwesomeIcon
            icon="square"
            className="text-background-error !mx-0"
          />
          <FontAwesomeIcon
            icon={['far', 'circle-xmark']}
            className="text-error text-3xl !mx-1.5"
          />
        </span>
        <div className="text-xl sm:text-4xl font-extrabold">
          Invalid Application Link
        </div>
      </div>
      <div className="font-light text-xl tracking-wide my-2">
        This application is no longer valid.
      </div>
      <div className="font-light text-lg tracking-wide mb-6">
        Please contact your merchant for a new application link or reach out to
        Credit Key Support for further assistance.
      </div>
      <div className="mt-6 sm:mt-12">
        <Support />
      </div>
    </div>
  );
}
