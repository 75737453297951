export default class GA4 {
  constructor() {
    this.dataLayer = window.dataLayer;
    if (!this.dataLayer) {
      this.dataLayer = {};
    }
  }

  init() {
    this.push('init');
  }

  identify(params = {}) {
    this.push('identity', params);
  }

  push(event, params = {}) {
    if (!this.dataLayer.push) return false;
    this.dataLayer.push({
      'event': event,
      ...params
    });
  }
}
