import useSession from "./useSession";
import useSessionEntity from "./useSessionEntity";

function usePromo() {
  const [session] = useSession();
  const merchant = useSessionEntity('merchant');

  // a merchant will always have an active, default promo
  // it's also possible for there to be multiple background promos
  // background promos override the active promo, at this time the newest background promo wins
  // additionally, a promo could already be assigned to the session (from borrower portal)
  // when the session has a promo, that overrides both the default and any background promos
  let promo = merchant?.active_promo;

  // the active promo always gets set on the session
  // but can be overridden with borrower specific, background promos
  // so we want to override ONLY if the id on the session doesn't match the active_promo
  if (session.promo_id && (session.promo_id !== merchant?.active_promo.get('id'))) {
    promo = merchant?.promos.find(p => p.get('id') === session.promo_id);
  }

  return [promo, merchant?.promos];
}

export default usePromo;
