import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';

import Error from '../../shared/Error';
import ReturnToMerchant from '../../shared/ReturnToMerchant';
import Support from '../../shared/Support';
import useRouteStatus from '../../../hooks/useRouteStatus';
import useSession from '../../../hooks/useSession';
import useSessionEntity from '../../../hooks/useSessionEntity';
import { asyncActions } from '../../../actions/general';
import { getOrderMessages } from '../../../actions/shopping_carts';
import { updateParent } from '../../../lib/utils/modal';

export default function OrderFail({ location }) {
  const [session, sessionActions] = useSession();
  const dispatch = useDispatch();

  const merchant = useSessionEntity('merchant');
  const cart = useSessionEntity('shopping_cart');

  const [orderMessages, setOrderMessages] = useState([]);

  useRouteStatus(location);

  useEffect(() => {
    if (cart) {
      dispatch(asyncActions(getOrderMessages(cart))).then((res) =>
        setOrderMessages(res.attributes.data),
      );
    }

    sessionActions.finish();
  }, []);

  const redirect = () => {
    if (session.mode === 'modal') {
      updateParent('complete', cart ? cart.cancel_url : '');
    } else {
      if (cart && cart.cancel_url && session.strategy !== 'async') {
        window.location.href = cart.cancel_url;
      } else if (merchant !== 'undefined') {
        window.location.href = merchant.url;
      } else {
        window.location.href = '/';
      }
    }
  };

  return (
    <div role="main"  className="mx-6 sm:mx-auto">
      <div className="my-4 flex items-center">
        <span className="fa-layers fa-fw text-5xl">
          <FontAwesomeIcon
            icon="square"
            className="text-background-error !mx-0"
          />
          <FontAwesomeIcon
            icon={['far', 'triangle-exclamation']}
            className="text-error text-3xl !mx-1.5"
          />
        </span>
        <div className="text-xl sm:text-4xl font-extrabold">We're sorry</div>
      </div>
      <div className="font-light sm:text-lg tracking-wide mb-6">
        This order exceeds your current credit limit.
      </div>

      <Error />

      <p className="tracking-wide mb-1 font-semibold sm:text-lg">
        Interested in raising your limit with Credit Key?
      </p>
      <p className="font-light text-sm sm:text-lg tracking-wide mb-6">
        Click here to submit a
        <a
          className="underline text-primary-blue mx-1 cursor-pointer"
          href="https://www.creditkey.com/credit-key-increase-request"
          target="_new"
        >
          trade line increase request
        </a>
        with us!
      </p>

      {cart &&
        orderMessages.map((m, idx) => {
          return (
            <p className="mb-5" key={idx}>
              {m}
            </p>
          );
        })}

      <div className="my-4 sm:my-10">
        <ReturnToMerchant
          asButton={true}
          merchant={merchant}
          redirect={redirect}
        />
      </div>
      <Support />
    </div>
  );
}
