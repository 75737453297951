import React from 'react';
import FullHeader from './FullHeader';
import LogoHeader from './LogoHeader';

export default function StaticHeader({
  isLoading,
  merchant,
  session,
  dismiss,
}) {
  const showCloseNavigation =
    merchant?.integration_configuration?.get('show_close_navigation') !== false;

  const showFullHeader = () => {
    if (session?.mode === 'modal') return true;

    return (
      merchant?.integration_configuration?.get(
        'show_full_header_navigation',
      ) !== false
    );
  };
  
  return !isLoading && showFullHeader() ? (
    <FullHeader
      dismiss={dismiss}
      merchant={merchant}
      showCloseNavigation={showCloseNavigation}
    />
  ) : (
    <LogoHeader merchant={merchant} />
  );
}
