import React from 'react';

export default function ModalFooter({ className }) {
  return (
    <footer
      className={`flex flex-col items-center text-tertiary-gray pt-0 ${className}`}
      role={ModalFooter.role}
    >
      <div className="flex flex-col items-center text-xs sm:text-sm modal:text-base text-center mt-2 sm:mt-6">
        <div>
          Loans made by Lead Bank. Visit
          <a
            href="https://www.creditkey.com/"
            target="_new"
            className="mx-1 underline text-primary-blue hover:text-primary-blue-hover"
          >
            creditkey.com
          </a>
          for more info.
        </div>
        <div className="flex flex-col sm:flex-row justify-between sm:divide-x mt-3 sm:my-1">
          <a
            href="https://www.creditkey.com/legal/privacy-policy"
            target="_blank"
            rel="noreferrer"
            className="px-2 no-underline text-primary-blue hover:text-primary-blue-hover"
          >
            Privacy Policy
          </a>
          <a
            href="https://www.creditkey.com/legal/terms-of-service-and-electronic-disclosures"
            target="_blank"
            rel="noreferrer"
            className="px-2 no-underline text-primary-blue hover:text-primary-blue-hover"
          >
            Terms of Service
          </a>
          <a
            href="https://lead.bank/privacy-and-terms"
            target="_new"
            className="px-2 text-primary-blue hover:text-primary-blue-hover"
          >
            Lead Bank Privacy Policy
          </a>
        </div>
      </div>
    </footer>
  );
}

ModalFooter.role = 'modal-footer-component';
