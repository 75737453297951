import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Approved from './finish/Approved';
import CallbackApi from './finish/CallbackApi';
import Declined from './finish/Declined';
import DeclinedOnReapply from './finish/DeclinedOnReapply';
import Error from './finish/Error';
import InstoreConfirm from './finish/InstoreConfirm';
import Loading from '../shared/Loading';
import OrderConfirmation from './finish/OrderConfirmation';
import OrderFail from './finish/OrderFail';
import PastDue from './finish/PastDue';
import Pending from './finish/Pending';
import Processing from './finish/Processing';
import Restricted from './finish/Restricted';
import StandaloneConfirm from './finish/StandaloneConfirm';
import Vip from './finish/Vip';
import useSessionEntity from '../../hooks/useSessionEntity';

export default function Finish({ match }) {
  const merchant = useSessionEntity('merchant');
  const company = useSessionEntity('company');
  const user = useSessionEntity('user');

  if (!merchant || !company || !user) {
    return <Loading className='w-14 h-14' />;
  }

  return (
    <Switch>
      <Route path={`${match.path}/approved`} component={Approved} />
      <Route path={`${match.path}/instore_order_confirmation`} component={InstoreConfirm} />
      <Route path={`${match.path}/declined`} component={Declined} />
      <Route path={`${match.path}/error`} component={Error} />
      <Route path={`${match.path}/pending`} component={Pending} />
      <Route path={`${match.path}/processing`} component={Processing} />
      <Route
        path={`${match.path}/standalone_order_confirmation`}
        component={StandaloneConfirm}
      />
      <Route
        path={`${match.path}/declined_on_reapply`}
        component={DeclinedOnReapply}
      />
      <Route path={`${match.path}/restricted`} component={Restricted} />
      <Route path={`${match.path}/past_due`} component={PastDue} />
      <Route path={`${match.path}/order_fail`} component={OrderFail} />
      <Route
        path={`${match.path}/order_confirmation`}
        component={OrderConfirmation}
      />
      <Route path={`${match.path}/callback_api`} component={CallbackApi} />
      <Route path={`${match.path}/vip`} component={Vip} />
    </Switch>
  );
}
