import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Alert from '../../shared/Alert';
import Error from '../../shared/Error';
import Loading from '../../shared/Loading';
import { asyncActions } from '../../../actions/general';
import { loading, getRoute, verifyOwnerPin } from '../../../actions/sessions';

let refs = [];

const getRefs = (refs) => {
  return refs.filter((r) => r.current);
};

const autoTab = (e) => {
  const BACKSPACE_KEY = 8;
  const TAB_KEY = 9;
  const DELETE_KEY = 46;
  const index = Number(e.target.getAttribute('data-index')) || 0;

  let nextElem;

  const finalRefs = getRefs(refs);

  if (e.keyCode === BACKSPACE_KEY) {
    nextElem = index > 0 && finalRefs[index - 1];
  } else if (e.keyCode !== DELETE_KEY && e.keyCode !== TAB_KEY) {
    nextElem = index < finalRefs.length - 1 && finalRefs[index + 1];
  }

  if (nextElem && nextElem.current) {
    nextElem.current.focus();
  }
};

const Input = (props) => {
  const ref = useRef(null);
  refs.push(ref);

  if (props.index < 1) {
    return (
      <Field
        autoFocus
        className="input sm:py-4 px-1 sm:px-2 font-semibold text-base border border-tertiary-gray rounded-lg mx-0.5 sm:mx-1 flex justify-center items-center cursor-pointer min-w-mobile-token sm:min-w-token text-center max-w-6 sm:max-w-auto h-12 sm:h-auto"
        data-index={props.index}
        role={'token' + props.index}
        type="tel"
        id={'token' + props.index}
        name={'token' + props.index}
        onKeyUp={props.autoTab}
        innerRef={ref}
        maxLength="1"
        placeholder="-"
      />
    );
  }

  return (
    <Field
      className="input sm:py-4 px-1 sm:px-2 font-semibold text-base border border-tertiary-gray rounded-lg mx-0.5 sm:mx-1 flex justify-center items-center cursor-pointer min-w-mobile-token sm:min-w-token text-center max-w-6 sm:max-w-auto h-12 sm:h-auto"
      data-index={props.index}
      role={'token' + props.index}
      type="tel"
      id={'token' + props.index}
      name={'token' + props.index}
      onKeyUp={props.autoTab}
      innerRef={ref}
      maxLength="1"
      placeholder="-"
    />
  );
};

const inputs = Array.from({ length: 6 }, (element, index) => (
  <div key={index} className="justify-center text-2xl my-2">
    <div className="field">
      <div className="control">
        <Input index={index} autoTab={autoTab} />
      </div>
    </div>
  </div>
));

export default function OwnerPin({ match }) {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.sessions.get('loading'));
  const [token, setToken] = useState('');
  const [error, setError] = useState(false);
  const formRef = useRef(null);

  const checkToken = async (val) => {
    const res = await dispatch(verifyOwnerPin(val));
    return res;
  };

  const onKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 13) {
      e.preventDefault();
    }
  };

  const makeToken = (values) =>
    values.token0 +
    values.token1 +
    values.token2 +
    values.token3 +
    values.token4 +
    values.token5;

  const save = (values, verified) => {
    dispatch(asyncActions(getRoute(), getRoute())).then(() =>
      dispatch(loading(false)),
    );
  };

  useEffect(() => {
    if (token.length === 6) {
      formRef.current.handleSubmit();
    }
  }, [token]);

  return (
    <div id="content" role="main"  className="mx-6 sm:mx-auto">
      <div role="form">
        <div className="my-4 flex items-center">
          <span className="fa-layers fa-fw text-5xl">
            <FontAwesomeIcon
              icon="square"
              className="text-secondary-blue !mx-0"
            />
            <FontAwesomeIcon
              icon={['far', 'hand-wave']}
              className="text-primary-blue text-3xl !mx-1.5"
            />
          </span>
          <div className="text-xl sm:text-4xl font-extrabold">Hi there!</div>
        </div>
        <div className="font-light sm:text-lg tracking-wide mb-6">
          For your security, please enter in the 6-digit code in the email from
          Credit Key
        </div>

        <Error />

        <Formik
          innerRef={formRef}
          initialValues={{
            token0: '',
            token1: '',
            token2: '',
            token3: '',
            token4: '',
            token5: '',
          }}
          validate={async (values) => {
            const errors = {};
            const token = makeToken(values);

            setToken(token);
            setError(false);

            return errors;
          }}
          onSubmit={async (values, { setSubmitting, setStatus, resetForm }) => {
            dispatch(loading(true));
            const verified = await checkToken(token);

            if (verified.data.attributes.authorized) {
              save(values, verified);
            } else {
              refs = [];
              setToken('');
              setError(true);
              resetForm();
              dispatch(loading(false));
            }
          }}
        >
          {({ isSubmitting }) => (
            <>
              {isLoading && <Loading className="w-14 h-14" />}

              {!isLoading && (
                <Form onKeyDown={onKeyDown}>
                  <div className="flex justify-start items-center gap-0.5 sm:gap-3">
                    {inputs}
                  </div>

                  {error && (
                    <Alert
                      containerClass="bg-background-error p-4 w-full m-auto"
                      icon={['far', 'triangle-exclamation']}
                      alertText="Invalid Token"
                    />
                  )}
                </Form>
              )}
            </>
          )}
        </Formik>
      </div>
    </div>
  );
}
