import { receiveEntity } from './entities';

const path = resource => {
  if (!resource) resource = '';
  return { resource: 'api/payment_methods/' + resource };
};

export const generateBankAgreement = (user) => {
  return (dispatch, getState, api) => {
    return api.post(
      path('0/generate_bank_agreement'),
      { user_id: user.id }
    );
  }
};

export const createPaymentMethodCreditCard = (payment_method, session_id) => {
  return (dispatch, getState, api) => {
    return api.post(path('credit_card'), { payment_method, session_id })
      .then(res => dispatch(receiveEntity(res)));
  };
};

export const createPaymentMethodBank = (payment_method, session_id) => {
  return (dispatch, getState, api) => {
    return api.post(path('bank_account'), { payment_method, session_id })
      .then(res => dispatch(receiveEntity(res)));
  };
};
