import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import ApplicationRecord from '../../../records/application';
import ColumnInput from '../../shared/inputs/ColumnInput';
import Error from '../../shared/Error';
import GA from '../../../lib/utils/ga';
import Heap from '../../../lib/utils/heap';
import Loading from '../../shared/Loading';
import OutlineButton from '../../shared/buttons/OutlineButton';
import ProgressBars from '../../shared/ProgressBars';
import PrimaryButton from '../../shared/buttons/PrimaryButton';
import StickyIndustryTypeAhead from '../../shared/inputs/StickyIndustryTypeAhead';
import StickyInput from '../../shared/inputs/StickyInput';
import StickySelect from '../../shared/inputs/StickySelect';
import useRouteStatus from '../../../hooks/useRouteStatus';
import useSessionEntity from '../../../hooks/useSessionEntity';
import useValidateRoute from '../../../hooks/useValidateRoute';
import { asyncActions } from '../../../actions/general';
import {
  business_types,
  emojiRegexTest,
  subDomains,
} from '../../../lib/constants/SiteVariables';
import { getIndustryTypes } from '../../../actions/industries';
import { getRoute, loading, updateSession } from '../../../actions/sessions';
import { setHeight } from '../../../lib/utils/modal';

const heap = new Heap('NewApplication');
const ga = new GA();

export default function NewBusinessQualifications({ location }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const application = useSessionEntity('application');
  const merchant = useSessionEntity('merchant');
  const shopping_cart = useSessionEntity('shopping_cart');
  const asyncErrors = useSelector((state) => state.general.get('errors'));
  const isLoading = useSelector((state) => state.sessions.get('loading'));
  const session = useSelector((state) => state.sessions.get('session'));
  const [foundUser, setFoundUser] = useState(false);
  const [isRouting, setIsRouting] = useState(false);

  const [industryOptions, setIndustryOptions] = useState([]);
  const [loadingIndustries, setLoadingIndustries] = useState(false);
  const [newApplication, setNewApplication] = useState(application);

  useRouteStatus(location);

  useEffect(() => {
    setLoadingIndustries(true);
    dispatch(getIndustryTypes())
      .then((res) =>
        setIndustryOptions(
          res.data.map((i) => ({
            id: i.id,
            value: i.attributes.description,
            label: i.attributes.description,
          })),
        ),
      )
      .finally(() => setLoadingIndustries(false));
  }, []);

  useEffect(() => {
    if (application) {
      setNewApplication(application);
    } else {
      setNewApplication(new ApplicationRecord());
    }
    dispatch(loading(false));
  }, [application]);

  useEffect(() => {
    if (shopping_cart && !application && !isLoading) {
      setNewApplication(
        new ApplicationRecord(shopping_cart && shopping_cart.billing_address),
      );
    }
  }, [isLoading]);

  useEffect(() => {
    if (merchant && merchant.isOnHold) history.push('/error');

    if (newApplication && merchant) {
      const content = document.getElementById('content');
      content &&
        session.mode === 'modal' &&
        setHeight(content.clientHeight + 220);
    }
  }, [newApplication, merchant]);

  useEffect(() => {
    if (foundUser) history.push('/init/existing_user');
  }, [foundUser]);

  useEffect(() => {
    if (
      asyncErrors.count() > 0 &&
      asyncErrors.find((e) => e && e.includes('User already registered'))
    ) {
      setFoundUser(true);
      dispatch(loading(false));
    }
  }, [asyncErrors]);

  const save = (values) => {
    setIsRouting(true);
    dispatch(loading(true));

    ga.identify({
      id: session.id,
      sessionId: session.id,
      sessionKey: session.session_key,
      merchantId: merchant.id,
      publicKey: session.public_key,
    });
    heap.track({
      merchant: merchant,
      session,
      application: values,
      payload: { amount: shopping_cart ? shopping_cart.grand_total : 0 },
    });

    return dispatch(
      asyncActions(
        updateSession(
          {
            ...session.setCustomFlag(subDomains.subDomain()).toJS(),
            application: new ApplicationRecord(values).toJS(),
          },
          { cart: false, prequal: false, merchant: false },
        ),
        getRoute(),
      ),
    ).then((res) => {
      dispatch(loading(false));
      setIsRouting(false);
    });
  };

  const isRecharge =
    session &&
    session.id &&
    (session.hasCustomFlag('recharge') || session.hasCustomFlag('instore'));

  const StandardHeader = () => {
    return (
      <div className="mt-4 mb-1 sm:mb-4">
        <div className="text-base sm:text-2xl md:text-4xl font-extrabold sm:mb-2">
          Buy Now, Pay on Your Terms
        </div>
        {merchant.active_promo.get('max_net_payment') > 30 && (
          <div className="mt-2 sm:mt-4">
            <p className="font-light text-xs sm:text-lg tracking-wide mb-2 sm:mb-0 text-primary-blue-hover">
              No payments for {merchant.active_promo.get('max_net_payment')}{' '}
              days. No early pay-off fees.
            </p>
          </div>
        )}
      </div>
    );
  };

  const RechargeHeader = () => {
    return (
      <div className="mt-2 sm:mt-4 text-base sm:text-xl md:text-4xl font-extrabold mb-2">
        Let's start with the basics
      </div>
    );
  };

  if (!useValidateRoute({ company: true })) return <></>;

  if (!newApplication || !merchant || loadingIndustries) {
    return <Loading className="w-14 h-14" containerClass="my-24 md:my-0" />;
  }

  if (newApplication && merchant) {
    return (
      <div id="content" role="main" className="mx-6 sm:mx-auto">
        <ProgressBars />
        {isRecharge ? RechargeHeader() : StandardHeader()}

        <div className="tracking-wide mb-4 font-light text-sm sm:text-base md:text-xl">
          This is an application for business financing. Applying will not
          affect your personal credit.
        </div>

        <Error />
        <Formik
          initialValues={{
            ...newApplication.toJS(),
          }}
          validate={async (values) => {
            const errors = {};
            Object.keys(values).forEach((key) => {
              const value = values[key];

              if (typeof value === 'string' && emojiRegexTest.test(value)) {
                errors[key] = '* Emojis are not accepted.';
              }
            });

            if (!values.company_name) {
              errors.company_name = '* Required';
            } else if (values.company_name.trim().length < 2) {
              errors.company_name =
                '* Company Name must be at least 2 characters';
            }
            if (!values.raw_annual_revenue) {
              errors.raw_annual_revenue = '* Required';
            } else if (
              parseInt(values.raw_annual_revenue.replace(/[^\d]/g, '')) < 1
            ) {
              errors.raw_annual_revenue = '* A valid number is required';
            }
            if (!values.business_type) errors.business_type = '* Required';
            if (!values.industry) errors.industry = '* Required';
            return errors;
          }}
          onSubmit={save}
        >
          {({ errors, setFieldValue, touched, values }) => (
            <Form role="form" className="mx-auto">
              <ColumnInput
                error={errors.company_name}
                touched={touched.company_name}
              >
                <StickyInput
                  id="company_name"
                  placeholder="Company Name"
                  values={values}
                />
              </ColumnInput>
              <ColumnInput>
                <StickyInput
                  id="dba"
                  placeholder="DBA, if any"
                  values={values}
                />
              </ColumnInput>
              <ColumnInput
                error={errors.business_type}
                touched={touched.business_type}
              >
                <StickySelect
                  id="business_type"
                  options={business_types}
                  placeholder="Business Type"
                  values={values}
                />
              </ColumnInput>
              <ColumnInput error={errors.industry} touched={touched.industry}>
                <StickyIndustryTypeAhead
                  id="industry"
                  placeholder="Industry"
                  setFieldValue={setFieldValue}
                  values={values}
                  options={industryOptions}
                />
              </ColumnInput>
              <ColumnInput
                error={errors.raw_annual_revenue}
                touched={touched.raw_annual_revenue}
              >
                <StickyInput
                  inputMode="numeric"
                  type="tel"
                  id="raw_annual_revenue"
                  placeholder="$ Total Annual Revenue"
                  mask={Number}
                  max={999999999999}
                  thousandsSeparator=","
                  scale={0}
                  values={values}
                />
              </ColumnInput>
              <PrimaryButton
                buttonText="Next"
                buttonColor="bg-primary-blue"
                hoverClass="hover:bg-primary-blue-hover"
                disabled={isLoading || isRouting}
                isLoading={isLoading || isRouting}
              />
            </Form>
          )}
        </Formik>
        <OutlineButton
          buttonText={
            <p>
              Already have a Credit Key account?
              <span className="hidden sm:inline ml-1">Sign In</span>
            </p>
          }
          buttonColor="border-primary-blue text-primary-blue hover:text-primary-blue-hover hover:border-primary-blue-hover"
          disabled={isLoading || isRouting}
          onClick={() => history.push('/init/existing_user')}
        />
      </div>
    );
  }
}
