import { receiveEntity } from './entities';

const path = resource => {
  if (!resource) resource = '';
  return { resource: 'api/prequalification/' + resource };
}

export const getPrequalification = id => {
  return (dispatch, getState, api) => {
    return api.get(path(id))
      .then(res => dispatch(receiveEntity(res)));
  }
}
