import React from 'react';

export default function FrozenFile({ user }) {
  return (
    <div>
       <div className="font-semibold sm:text-lg tracking-wide mb-2 sm:mb-4">
        Hi {user?.first_name},
      </div>

      <div className="font-light text-xs sm:text-base tracking-wider leading-5 mb-1.5 sm:mb-3">
        It looks like your credit file has been frozen at your request. You'll
        need to contact Equifax to temporarily lift your credit freeze.
      </div>

      <div className="font-light text-xs sm:text-base tracking-wider leading-5 mb-1.5 sm:mb-3">
        You can lift your freeze online at
        <a href="http://my.equifax.com"> my.equifax.com</a>, or by phone by
        calling Equifax’s automated line at (800) 349-9960 or calling Customer
        Care at (888) 298-0045. Afterward, your Equifax credit report will be
        frozen again.
      </div>

      <div className="font-light text-xs sm:text-base tracking-wider leading-5 mb-1.5 sm:mb-3">
        When you lift your freeze or lock online or by phone, it usually takes
        effect quickly, sometimes within minutes. However, to be on the safe
        side, you should wait about an hour before you come back to Credit Key
        and continue your application.
      </div>

      <div className="font-light text-xs sm:text-base tracking-wider leading-5 mb-1.5 sm:mb-3">
        When you lift your freeze by mail, it can take up to a few days for the
        lift to take effect.
      </div>

      <div className="font-light text-xs sm:text-base tracking-wider leading-5 mb-1.5 sm:mb-3">
        If you've taken the steps explained here and still have trouble applying
        for a loan, please contact{' '}
        <a href="mailto: support@creditkey.com">Credit Key Support</a>.
      </div>

      <div className="font-light text-xs sm:text-base tracking-wider leading-5 mb-1.5 sm:mb-3">
        If we’re unable to reach you within 10 business days, we’ll consider
        your credit application withdrawn.
      </div>
    </div>
  );
}
