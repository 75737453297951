import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  asyncActions,
  clearErrors,
  toggleRethrow,
} from '../../../actions/general';
import Alert from '../../shared/Alert';
import Loading from '../../shared/Loading';
import ProgressBars from '../../shared/ProgressBars';
import UserVerification from '../../shared/UserVerification';
import useRouteStatus from '../../../hooks/useRouteStatus';
import useSessionEntity from '../../../hooks/useSessionEntity';
import { formatPhoneNumber } from '../../../lib/utils/formatters';
import { getUser } from '../../../actions/users';
import { loading, getRoute } from '../../../actions/sessions';
import { updateApplication } from '../../../actions/applications';

export default function PhoneVerification({ location }) {
  const dispatch = useDispatch();
  const user = useSessionEntity('user');
  const application = useSessionEntity('application');
  const errors = useSelector((state) => state.general.get('errors'));
  const rethrow = useSelector((state) => state.general.get('rethrow'));
  const isLoading = useSelector((state) => state.sessions.get('loading'));
  const [updatingPhone, setUpdatingPhone] = useState(false);
  const [bodyText, setBodyText] = useState();

  const standardConfirmText = (
    <>
      <div className="mt-4 mb-1 sm:mb-4">
        <div className="text-xl sm:text-4xl font-extrabold">
          Please confirm your mobile number
        </div>
      </div>
      <div className="tracking-wide mb-6 font-light text-sm sm:text-lg">
        We'll text a 6-digit code to your phone
      </div>
    </>
  );

  const updatePhoneText = (
    <>
      <div className="mt-4 mb-1 sm:mb-4">
        <div className="text-xl sm:text-4xl font-extrabold">
          Please confirm your mobile number
        </div>
      </div>
      <div className="tracking-wide mb-6 font-light text-lg">
        We're unfortunately unable to find an association between
        <div>{formatPhoneNumber(user?.phone)} and your name.</div>
      </div>
      <div className="tracking-wide mb-6 font-light text-lg">
        Please double-check the number. It must be a mobile number with your
        name on the account. You can update the number below.
      </div>
    </>
  );

  const foundPhoneError = () =>
    errors.count() > 0 && errors.first() && errors.first().match(/phone/i);

  const updateUserPhone = (phone) => {
    if (phone) {
      dispatch(toggleRethrow());

      return dispatch(
        asyncActions(
          updateApplication(application.set('phone', phone)),
          getUser(user.id),
        ),
      ).then(
        () => rethrow && dispatch(toggleRethrow()) && dispatch(clearErrors()),
      );
    } else {
      return new Promise((resolve, reject) => resolve());
    }
  };

  useEffect(() => {
    const failureReasons = ['cognito_phone_fail', 'phone_fail'];
    setUpdatingPhone(failureReasons.some(failureReason => application.reasons.includes(failureReason)));
  }, []);

  useEffect(() => {
    setBodyText(updatingPhone ? updatePhoneText : standardConfirmText);
  }, [updatingPhone]);

  useEffect(() => {
    dispatch(loading(true));

    if (bodyText) dispatch(loading(false));
  }, [bodyText]);

  useRouteStatus(location);

  if (!bodyText || !user) return <Loading className="w-14 h-14" containerClass="my-24 sm:my-0" />;

  return (
    <div id="content" role="main"  className="mx-6 sm:mx-auto">
      <ProgressBars />
      <div>{bodyText}</div>

      {foundPhoneError() && (
        <Alert
          containerClass="bg-red-50 p-4 w-full m-auto"
          icon={['far', 'triangle-exclamation']}
          alertText="That phone number is already in use"
        />
      )}

      <UserVerification
        defaultValue={user.phone}
        icon={false}
        onSuccessPath="verify"
        onLoading={loading}
        isLoading={isLoading}
        onSideEffect={updateUserPhone}
        onSubmit={getRoute}
        buttonText={updatingPhone ? 'Confirm' : 'Next'}
      />
    </div>
  );
}
