import React from 'react';
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import InvalidCKApplication from './InvalidCKApplication';
import Support from './shared/Support';
import useSessionEntity from '../hooks/useSessionEntity';
import { merchantIsCreditKey } from '../lib/utils/merchant_restricted_text';

export default function Invalid() {
  const company = useSessionEntity('company');
  const merchant = useSessionEntity('merchant');
  const cart = useSessionEntity('shopping_cart');
  const redirects = ['declined', 'pending'];

  if (merchant && merchantIsCreditKey(merchant)) return <InvalidCKApplication />;

  if (company && company.underwriting_status === 'approved' && !cart) {
    return <Redirect to="/finish/approved" />;
  }

  if (company && company.underwriting_status === 'approved' && cart) {
    return <Redirect to="/purchase/terms" />;
  }

  if (company && redirects.includes(company.underwriting_status)) {
    return <Redirect to={`/finish/${company.underwriting_status}`} />;
  }

  return (
    <div role="main" className="mx-6 sm:mx-auto">
      <div className="my-4 flex items-center">
        <span className="fa-layers fa-fw text-5xl">
          <FontAwesomeIcon
            icon="square"
            className="text-background-error !mx-0"
          />
          <FontAwesomeIcon
            icon={['far', 'circle-xmark']}
            className="text-error text-3xl !mx-1.5"
          />
        </span>
        <div className="text-xl sm:text-4xl font-extrabold">404: Uh Oh!</div>
      </div>
      <div className="text-lg sm:text-3xl font-semibold">
        You have reached this page in error.
      </div>
      {!company && (
        <div className="font-light text-lg tracking-wide mt-2 mb-6">
          Something seems to be wrong. Please contact Credit Key support.
        </div>
      )}
      <div className="mt-6 sm:mt-12">
        <Support />
      </div>
    </div>
  );
}
