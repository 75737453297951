import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  clearPublicKey,
  createSession,
  getRoute,
  loading,
} from '../../../actions/sessions';
import Loading from '../../shared/Loading';
import { clearEntities } from '../../../actions/entities';
import { error } from '../../../actions/general';
import { subDomains } from '../../../lib/constants/SiteVariables';

export default function ApplyNow({ match }) {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loading(true));
    dispatch(clearPublicKey());
    dispatch(
      createSession(
        match.params.merchant_public_key,
        match.params.prequalification_id,
        subDomains.authorized() ? [subDomains.subDomain()] : [],
      ),
    )
      .then((res) => {
        dispatch(clearEntities('application'));
        dispatch(clearEntities('company'));
        dispatch(clearEntities('user'));
      })
      .then((res) => dispatch(getRoute()))
      .catch((err) => {
        dispatch(error(err));
        history.push('/error');
      });
  }, []);

  return (
    <div data-testid="applynow" className="mx-3 sm:mx-auto">
      <Loading className="w-14 h-14" containerClass="my-24 sm:my-0 min-h-[30vh]" />
    </div>
  );
}
