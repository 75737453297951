import { createBrowserHistory } from 'history';
import { createStore, compose, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import ReduxThunk from 'redux-thunk';
import createRootReducer from './reducers';
import Network from './lib/utils/network';

import { getUserToken } from './actions/authentication';

export const history = createBrowserHistory();
const composeEnhancers = (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const setupAPI = store => next => action => {
  const localStorageToken = getUserToken();
  const token = localStorageToken.token || store.getState().authentication.get('token');

  const api = Network(null, token);

  if (typeof action === 'function') {
    return action(store.dispatch, store.getState, api);
  }

  return next(action);
};

export const configureStore = () => {
  const s = createStore(
    createRootReducer(history),
    composeEnhancers(
      applyMiddleware(
        routerMiddleware(history),
        setupAPI,
        ReduxThunk
      )
    )
  );

  if (module.hot && process.env.NODE_ENV !== 'production') {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept(createRootReducer(history), () => {
      const nextRootReducer = createRootReducer(history);
      s.replaceReducer(nextRootReducer);
    });
  }

  return s;
};

export const store = configureStore();
