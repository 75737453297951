import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { finishSession, loading, getRoute } from '../actions/sessions';
import { asyncActions } from '../actions/general';

function useSession() {
  const [session] = useState(
    useSelector((state) => state.sessions.get('session'))
  );
  const dispatch = useDispatch();

  const sessionActions = {
    finish: () => {
      dispatch(loading(true));
      return dispatch(asyncActions(finishSession(session.action))).then(() =>
        dispatch(loading(false))
      );
    },
    nextStep: () => dispatch(getRoute()),
  };

  return [session, sessionActions];
}

export default useSession;
