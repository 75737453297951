import React from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PrimaryButton from './buttons/PrimaryButton';
import useSessionEntity from '../../hooks/useSessionEntity';
import { emitEvent, updateParent } from '../../lib/utils/modal';

export default function ReturnToMerchant({ merchant, redirect, asButton }) {
  const session = useSelector((state) => state.sessions.get('session'));
  const user = useSessionEntity('user');
  const cart = useSessionEntity('shopping_cart');

  const onClick = () => {
    if (session && session.mode === 'modal') {
      emitEvent(
        user,
        {
          action: 'completed',
        },
        cart,
      );
    }

    if (redirect) {
      return redirect();
    } else if (session.mode === 'modal') {
      updateParent('cancel', null);
    } else {
      window.location.href = merchant.url;
    }
  };

  return (
    <>
      {merchant &&
        merchant.url &&
        (asButton ? (
          <PrimaryButton
            buttonText="Return to Merchant"
            buttonColor="bg-primary-blue"
            hoverClass="hover:bg-primary-blue-hover"
            onClick={onClick}
          />
        ) : (
          <div className="flex items-center gap-2 text-xs sm:text-sm w-max my-4">
            <FontAwesomeIcon
              icon="arrow-left"
              className="text-primary-blue mt-0.5"
            />
            <button onClick={onClick} className="hidden md:inline text-primary-blue">
              Back to Merchant
            </button>
            <button onClick={onClick} className="inline md:hidden text-primary-blue">
              Merchant Site
            </button>
          </div>
        ))}
    </>
  );
}
