import React from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import useSessionEntity from '../../hooks/useSessionEntity';

export default function ProgressBars() {
  let title;
  const { pathname } = useLocation();
  const cart = useSessionEntity('shopping_cart');

  const path = pathname.split('/')[1];
  const stages = !cart
    ? ['init', 'apply', 'finish']
    : ['init', 'apply', 'purchase', 'finish'];

  const currentStageIndex = stages.findIndex((stage) => stage === path);

  switch (path) {
    case 'init':
      title = 'Get Started';
      break;
    case 'apply':
      title =
        !cart && pathname.split('/')[2] === 'verify'
          ? 'Almost Done!'
          : 'Confirm Details';
      break;
    case 'purchase':
      title =
        pathname.split('/')[2] === 'confirm'
          ? 'Almost Done!'
          : 'Complete Order';
      break;
    case 'finish':
      title = ['pending', 'declined'].includes(pathname.split('/')[2])
        ? 'Application Complete'
        : 'All Set!';
      break;
    default:
      title = 'Almost Done!';
      break;
  }

  return (
    <div className="w-full flex items-center gap-2 sm:gap-4">
      <p className="font-bold text-secondary-gray text-xs sm:text-sm">{title}</p>
      <div className="w-1/2 flex items-center gap-2">
        {stages.map((s, idx) => {
          return (
            <div
              key={idx}
              className={classNames(
                'rounded h-2',
                idx <= currentStageIndex
                  ? 'bg-primary-blue h-2'
                  : 'bg-primary-border-gray h-2',
                s === path ? 'w-16' : 'w-8',
              )}
            />
          );
        })}
      </div>
    </div>
  );
}
