import moment from 'moment';
import { isEmpty } from 'lodash';
import * as ActionTypes from '../lib/constants/ActionTypes';
import { pdGetPublicKey } from '../lib/constants/PersistentData';
import {
  getLocalItem,
  setLocalItem,
  removeLocalItem,
  isLocalItemExpired
} from '../lib/utils/local_storage';

const defaultLabel = 'creditkey';

const isLocalStorageAvailable = () => {
  let test = 'test';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
};

export const getPublicKey = () => {
  if (isLocalStorageAvailable()) {
    const ck = removeAuthorizationIfExpired(getLocalItem(defaultLabel));

    if (ck) return Object.keys(ck)[0];
    return false;
  } else {
    return false;
  }
};

export const savePublicKey = (key) => {
  if (isLocalStorageAvailable()) {
    setLocalItem(defaultLabel, {
      [key]: {
        user: {}
      },
      timestamp: moment().toISOString(),
    });
  }
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.SET_PUBLIC_KEY,
      payload: key,
    });
  };
};

export const setToken = (res) => {
  return (dispatch, getState) => {
    if (!res.access_token) return false;

    if (isLocalStorageAvailable()) {
      const ck = getLocalItem(defaultLabel);

      if (ck) {
        ck.timestamp = moment().toISOString();
        ck[pdGetPublicKey()].user = {
          token: res,
        };
        setLocalItem(defaultLabel, ck);
      }
    }
    return dispatch({
      type: ActionTypes.SET_TOKEN,
      payload: res,
    });
  };
};

export const getUserToken = () => {
  if (isLocalStorageAvailable()) {
    const ck = removeAuthorizationIfExpired(getLocalItem(defaultLabel));

    if (ck && !isEmpty(ck[pdGetPublicKey()].user)) return ck[pdGetPublicKey()].user;
    return false;
  } else {
    return false;
  };
};

export const tokenIsExpired = (token) => {
  if (isLocalStorageAvailable()) {
    const ck = getLocalItem(defaultLabel);
    return ck && isLocalItemExpired(ck);
  }

  return token && isTokenExpired(token);
};

export const isTokenExpired = (token, override) => {
  if (!token || !token.created_at) return true;

  const now = moment();
  const timestamp = moment.unix(token.created_at);
  const timeout = override || 4; // hours

  if (now.subtract(timeout, 'hours').isBefore(timestamp)) {
    return false;
  }

  return true;
};

export const removeAuthorizationIfExpired = data => {
  if (isLocalStorageAvailable()) {
    if (data && isLocalItemExpired(data)) data = removeToken(data);

    return data;
  }
  if (data && isTokenExpired(data)) data = removeToken(data);

  return data;
};

export const removeToken = data => {
  if (isLocalStorageAvailable()) {
    const key = Object.keys(data)[0];

    data[key].user = {};

    setLocalItem(defaultLabel, data);

    return getLocalItem(defaultLabel);
  }
};

export const removeCreditKey = () => {
  if (isLocalStorageAvailable()) {
    removeLocalItem(defaultLabel);
  }
};
