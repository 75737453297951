import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';

import CreditCardDisplay from '../../shared/CreditCardDisplay';
import Error from '../../shared/Error';
import Loading from '../../shared/Loading';
import ProgressBars from '../../shared/ProgressBars';
import ReturnToMerchant from '../../shared/ReturnToMerchant';
import Support from '../../shared/Support';
import useSession from '../../../hooks/useSession';
import useSessionEntity from '../../../hooks/useSessionEntity';
import { asyncActions } from '../../../actions/general';
import { confirmStandaloneShoppingCart } from '../../../actions/shopping_carts';
import { getVirtualCardDetails } from '../../../actions/companies';
import { loading } from '../../../actions/sessions';

export default function StandaloneConfirm() {
  const [session, sessionActions] = useSession();
  const dispatch = useDispatch();
  const merchant = useSessionEntity('merchant');
  const cart = useSessionEntity('shopping_cart');
  const company = useSessionEntity('company');
  const [errorFound, setErrorFound] = useState(false);
  const [cardDetail, setCardDetails] = useState();

  useEffect(() => {
    if (cart.status !== 'ordered') {
      dispatch(loading(true));
      dispatch(asyncActions(confirmStandaloneShoppingCart(cart)))
        .then(() => dispatch(loading(false)))
        .then(() => sessionActions.finish());
    } else {
      setErrorFound(true);

      sessionActions.finish();
    }
  }, []);

  useEffect(() => {
    const { order_id } = cart;

    if (order_id && cart.using_virtual_card && !cardDetail) {
      dispatch(loading(true));
      dispatch(
        asyncActions(getVirtualCardDetails(company.id, session.session_key)),
      )
        .then(
          (res) =>
            setCardDetails(res.data.attributes) && dispatch(loading(false)),
        )
        .catch((err) => dispatch(loading(false)));
    }
  }, [cart]);

  if (errorFound) {
    return (
      <div role="main"  className="mx-6 sm:mx-auto">
        <div className="my-4 flex items-center">
          <span className="fa-layers fa-fw text-5xl">
            <FontAwesomeIcon
              icon="square"
              className="text-background-error !mx-0"
            />
            <FontAwesomeIcon
              icon={['far', 'triangle-exclamation']}
              className="text-error text-3xl !mx-1.5"
            />
          </span>
          <div className="text-xl sm:text-4xl font-extrabold">We're sorry!</div>
        </div>
        <div className="tracking-wide mb-6 font-light text-lg">
          Something isn't right with your order. Please contact support for
          further guidance.
        </div>
        <Support />
      </div>
    );
  }

  return (
    <div role="main"  className="mx-6 sm:mx-auto">
      <ProgressBars />
      <div className="my-4 flex items-center">
        <span className="fa-layers fa-fw text-5xl">
          <FontAwesomeIcon
            icon="square"
            className="text-background-success !mx-0"
          />
          <FontAwesomeIcon
            icon={['far', 'circle-check']}
            className="text-success text-3xl !mx-1.5"
          />
        </span>
        <div className="text-xl sm:text-4xl font-extrabold">
          Congratulations!
        </div>
      </div>

      <Error />

      <div className="font-semibold text-lg tracking-wide mb-6">
        {!cart.using_virtual_card
          ? 'Your order has been processed.'
          : `Please find your virtual card details below for use with ${merchant.name}.`}
      </div>

      {cart.using_virtual_card && !cardDetail && (
      <>
        <p className="font-semibold text-lg tracking-wide mb-6">
          Loading Card Data...
        </p>
        <Loading className="w-14 h-14" />
      </>
      )}

      {cardDetail && <CreditCardDisplay {...cardDetail} />}

      {!cart.using_virtual_card && (
        <ReturnToMerchant merchant={merchant} asButton={true} />
      )}
    </div>
  );
}
