import { Record } from 'immutable';

export default class PrequalificationRecord extends Record({
    id: null,
    owner_first_name: '',
    owner_last_name: '',
    email: '',
    company_name: '',
    street: '',
    city: '',
    state: '',
    zip: ''
  }) {
    get full_name() {
      return `${this.first_name || ''} ${this.last_name || ''}`.trim();
    }
  }
