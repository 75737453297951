import React, { useEffect, useState } from 'react';
import VerificationInput from 'react-verification-input';
import { useDispatch } from 'react-redux';

import Alert from './Alert';
import UserRecord from '../../records/user';
import { useAuth } from '../../hooks/useAuth';
import {
  asyncActions,
  clearErrors,
  toggleRethrow,
} from '../../actions/general';

export default function UserTokenVerification({
  children, // any inherited DOM
  onAfterAuth, // function to call after authentication
  expired, // local storage expiration boolean
  match,
  onLoading, // FUNCTION action creator callback to set loading state
  isLoading, // VAR loading state
  onLockout, // function to call if user is locked out
  onNotBorrower, // function to call if user is not borrower type
  onSaveToken, // callback to save token locally
  twofactor,
}) {
  const dispatch = useDispatch();
  const { verifyToken } = useAuth();

  const [token, setToken] = useState('');
  const [error, setError] = useState(false);

  const checkToken = async (val) => {
    const res = await verifyToken(
      match.params.type,
      match.params.contact,
      val,
    )();
    return res;
  };

  const apiCalls = (apiAttempts, expired, verifiedUser, verifiedToken) => {
    dispatch(onLoading(true));
    dispatch(asyncActions(onSaveToken(verifiedToken), expired && clearErrors()))
      .then(() => onAfterAuth(twofactor, verifiedUser, verifiedToken))
      .catch((err) => {
        // In some cases, like coming back into a started session, we may not
        // have a token in local storage yet, if so first set the auth'd users
        // token and try again.
        if (err.status === 401 && apiAttempts < 2) {
          dispatch(clearErrors());
          dispatch(toggleRethrow());
          apiAttempts++;

          // now retry with a saved token
          dispatch(onSaveToken(verifiedToken));
          apiCalls(apiAttempts, expired, verifiedUser, verifiedToken);
        }
      });
  };

  const save = (verified) => {
    const verifiedUser = new UserRecord(verified.user);
    let apiAttempts = 1;

    dispatch(toggleRethrow());
    apiCalls(apiAttempts, expired, verifiedUser, verified.token);
  };

  useEffect(() => {
    let validToken = false;
    if (token.length === 6 && !validToken) {
      const tokenParts = token.split('');
      validToken = true;

      tokenParts.forEach((char) => {
        if (char === undefined || char === null || char === '') {
          validToken = false;
        }
      });

      if (validToken) submitToken(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const submitToken = async (givenToken) => {
    dispatch(onLoading(true));
    const verified = await checkToken(givenToken);

    if (verified.locked) {
      // IF USER LOCKED OUT
      onLockout();
      dispatch(onLoading(false));
    } else if (verified.user_is_not_borrower) {
      // IF USER IS NON-BORROWER
      onNotBorrower();
    } else if (verified.authorized) {
      save(verified);
    } else {
      setToken('');
      setError(true);
      dispatch(onLoading(false));
    }
  };

  const verificationInputChanged = (value) => {
    setToken(value);
    setError(false);
  };

  return (
    <div role="form">
      {isLoading && children}
      {!isLoading && (
        <VerificationInput
          autoFocus={true}
          debug={false}
          length={6}
          onChange={verificationInputChanged}
          placeholder="-"
          validChars="0-9"
          value={token}
          inputProps={{ autoComplete: 'one-time-code', type: 'tel' }}
          classNames={{
            container: 'tokens flex justify-center items-center h-12 sm:h-14',
            character:
              'input py-2 sm:py-4 sm:px-2 mobile:px-5 text-base font-semibold mobile:text-lg border border-tertiary-gray rounded-lg sm:mx-1 flex justify-center items-center cursor-pointer min-w-mobile-token sm:min-w-token',
            characterInactive: 'text-tertiary-gray bg-white',
            characterSelected: 'border-primary-blue border-1',
          }}
        />
      )}

      {!isLoading && error && (
        <Alert
          containerClass="bg-background-error p-4 w-full m-auto my-4"
          icon={['far', 'triangle-exclamation']}
          alertText="Invalid Token"
        />
      )}
    </div>
  );
}
