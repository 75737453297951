import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import { useDispatch, useSelector } from 'react-redux';

import Error from '../../shared/Error';
import ProgressBars from '../../shared/ProgressBars';
import PrimaryButton from '../../shared/buttons/PrimaryButton';
import Heap from '../../../lib/utils/heap';
import useRouteStatus from '../../../hooks/useRouteStatus';
import useSessionEntity from '../../../hooks/useSessionEntity';
import useValidateRoute from '../../../hooks/useValidateRoute';
import { asyncActions } from '../../../actions/general';
import { getRoute, loading } from '../../../actions/sessions';
import { history } from '../../../store';
import { updateApplication } from '../../../actions/applications';

const heap = new Heap();

export default function Ownership({ location }) {
  const dispatch = useDispatch();
  const [owner, isOwner] = useState(true);

  const isLoading = useSelector((state) => state.sessions.get('loading'));
  const application = useSessionEntity('application');
  const user = useSessionEntity('user');
  const merchant = useSessionEntity('merchant');

  useRouteStatus(location);

  useEffect(() => {
    if (user) heap.identify(user.email);
  }, [application, merchant]);

  const save = () => {
    dispatch(loading(true));

    dispatch(
      asyncActions(
        updateApplication(application.set('is_authorized_signer', owner)),
        owner && getRoute(),
      ),
    )
      .then(() => !owner && history.push('/apply/notify_owner'))
      .then(() => dispatch(loading(false)));
  };

  if (!useValidateRoute({ apply: true, company: true })) return <></>;

  return (
    <div role="main" className="mx-6 sm:mx-auto">
      <ProgressBars />
      <div className="mt-4 mb-1 sm:mb-4">
      <div className="text-base sm:text-xl sm:text-4xl font-extrabold sm:mb-2">
          Are you an owner of
          <span className="mx-1">{application.company_name}?</span>
        </div>
      </div>

      <Error />

      <div className="flex flex-col items-center gap-4 w-full my-6 sm:my-10">
        <div
          className={classNames(
            'flex items-center border rounded-lg py-3 px-6 w-full group',
            owner ? 'border-primary-blue' : 'primary-border-gray ',
          )}
          onClick={() => isOwner(true)}
        >
          <input
            id="is_authorized_signer"
            name="is_authorized_signer"
            onChange={() => isOwner(true)}
            value={owner}
            defaultChecked={owner}
            type="radio"
            className="h-4 w-4 sm:h-5 sm:w-5 border-tertiary-gray group-focus:ring-primary-blue mt-1"
          />
          <label
            htmlFor="is_authorized_signer"
            className="ml-4 block text-sm sm:text-lg font-light tracking-wide"
          >
            I'm an owner and authorized to sign on behalf of
            <span className="mx-1">{application.company_name}</span>
          </label>
        </div>
        <div
          className={classNames(
            'flex items-center border rounded-lg py-3 px-6 w-full group',
            !owner ? 'border-primary-blue' : 'primary-border-gray',
          )}
          onClick={() => isOwner(false)}
        >
          <input
            id="is_not_authorized_signer"
            name="is_authorized_signer"
            onChange={() => isOwner(false)}
            value={owner}
            checked={!owner}
            type="radio"
            className="h-4 w-4 sm:h-5 sm:w-5 border-tertiary-gray group-focus:ring-primary-blue mt-1"
          />
          <label
            htmlFor="is_not_authorized_signer"
            className="ml-4 block text-sm sm:text-lg font-light tracking-wide"
          >
            I need quick approval from a business owner
          </label>
        </div>
      </div>
      <PrimaryButton
        buttonText="Next"
        buttonColor='bg-primary-blue'
        hoverClass='hover:bg-primary-blue-hover'
        disabled={isLoading}
        isLoading={isLoading}
        onClick={save}
      />
    </div>
  );
}
