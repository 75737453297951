import React from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Support from '../shared/Support';
import { clearErrors } from '../../actions/general';
import { tokenIsExpired } from '../../actions/authentication';

export default function Error() {
  const dispatch = useDispatch();
  const errors = useSelector((state) => state.general.get('errors'));
  const debug = useSelector((state) => state.general.get('debugging'));
  const token = useSelector((state) => state.authentication.get('token'));

  if (errors.count() < 1) {
    return  (<></>);
  }

  return (
    <div className="modal:mb-4 rounded-lg bg-background-error p-4 relative">
      <div
        onClick={() => dispatch(clearErrors())}
        className="absolute top-1 right-1 justify-end cursor-pointer"
      >
        <FontAwesomeIcon
          icon="times"
          className="h-4 w-4 text-error hover:text-icon-error m-3"
          aria-hidden="true"
        />
      </div>
      <div>
        {tokenIsExpired(token) && <Redirect to="/init/existing_user" />}

        <div className="flex justify-around">
          <FontAwesomeIcon
            icon={['far', 'triangle-exclamation']}
            className="h-5 w-5 text-icon-error mt-2 hidden mobile:inline"
            aria-hidden="true"
          />
          <div className="text-xs sm:text-sm flex flex-col gap-2 ml-2 break-words sm:w-[90%]">
            <h3>
              We're sorry, an error has occurred. Please try the last action
              again.
            </h3>
            <p>If this issue persists, please contact Credit Key support.</p>
            <p>Below are details about the error:</p>
            <div className="my-1 text-xs sm:text-sm text-error break-all">
              <ul role="list" className="list-disc space-y-1 pl-0 sm:pl-5">
                {errors.map((e, i) => (
                  <li className="block font-extrabold break-normal" key={i}>
                    {e}
                  </li>
                ))}
                <p className="font-semibold">Public Key:</p>
                <code className="w-full sm:mx-1">{debug.get('key')}</code>
              </ul>
            </div>
            <Support asText={true} />
          </div>
        </div>
      </div>
    </div>
  );
}
