import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateSession } from '../actions/sessions';

function useRouteStatus(location) {
  const dispatch = useDispatch();
  const session = useSelector(state => state.sessions.get('session'));
  const [changed, setChanged] = useState(false);
  const [controller, action] = location.pathname.split('/').slice(1);

  useEffect(() => {
    if ((controller && controller !== session.workflow) || (action && action !== session.action)) {
      dispatch(updateSession(
        session
          .set('workflow', controller)
          .set('action', action)
      ))
      .then(res => setChanged(true))
      .catch(err => console.error(err));
    }
  }, []);

  return changed;
}

export default useRouteStatus;
