import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PrimaryButton from '../../../shared/buttons/PrimaryButton';
import ProgressBars from '../../../shared/ProgressBars';
import ReturnToMerchant from '../../../shared/ReturnToMerchant';
import Support from '../../../shared/Support';
import { borrowerPortalUrl } from '../../../../lib/constants/SiteVariables';

export default function Recharge({ merchant, session }) {
  const vcInstoreURL = process.env.REACT_APP_VCINSTORE_BASE_URL.replace(
    '%s',
    merchant?.slug,
  );

  return (
    <div>
      <ProgressBars />
      <div className="my-4 flex items-center">
        <span className="fa-layers fa-fw text-5xl">
          <FontAwesomeIcon
            icon="square"
            className="text-secondary-blue !mx-0"
          />
          <FontAwesomeIcon
            icon={['far', 'thumbs-up']}
            className="text-primary-blue text-3xl !mx-1.5"
          />
        </span>
        <div className="text-xl sm:text-4xl font-extrabold">
          You're good to go!
        </div>
      </div>

      <div className="leading-7">
        <p>
          Your {merchant.name} rep will be able to see that you're now approved
          for Credit Key{' '}
          {session.hasCustomFlag('instore') ? 'In-Store' : 'Recharge'}. They'll
          be able to place orders on your behalf, using your selected terms.
        </p>
      </div>

      {merchant?.bank_bypass && (
        <div className="max-w-[400px] mx-auto lg:max-w-full lg:mx-0 rounded-md bg-secondary-blue p-4 text-left my-6">
          <div className="flex">
            <div className="flex-shrink-0">
              <FontAwesomeIcon icon={['fal', 'info-circle']} />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-navy-blue font-avenir-black">
                Important Note
              </h3>
              <div className="text-sm text-navy-blue">
                <p className="my-2">
                  In order to place orders with {merchant.name}, you will need
                  to connect a bank account with Credit Key.
                </p>
                <p className="my-2">
                  Please visit the Credit Key Borrower Portal to login and see
                  instructions to connect your bank.
                </p>
              </div>
              <div className="mt-4">
                <Link
                  to={{ pathname: borrowerPortalUrl }}
                  target="_blank"
                  className="inline-flex items-center justify-center rounded-md text-primary-blue font-bold hover:text-primary-blue-hover text-sm"
                >
                  <span className="mr-2">Go to My Portal</span>
                  <FontAwesomeIcon icon={['far', 'arrow-right']} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
      {merchant?.supports_vcinstore ? (
        <PrimaryButton
          buttonText={`Get My ${merchant.name} Virtual Card`}
          buttonColor="bg-primary-blue"
          hoverClass="hover:bg-primary-blue-hover"
          onClick={() => (window.location.href = vcInstoreURL)}
        />
      ) : (
        <ReturnToMerchant merchant={merchant} asButton={true} />
      )}
      <Support />
    </div>
  );
}
