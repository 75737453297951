import moment from 'moment';

export const isLocalItemExpired = (item, override) => {
  if (!item || !item.timestamp) return true;

  const now = moment();
  const timestamp = moment(item.timestamp);
  const timeout = override || 4; // hours

  if (now.subtract(timeout, 'hours').isBefore(timestamp)) {
    return false;
  }

  return true;
}

export const getLocalItem = label => {
  const item = localStorage.getItem(label);
  return JSON.parse(item);
}

export const setLocalItem = (label, data) => {
  localStorage.setItem(label, JSON.stringify(data));
}

export const removeLocalItem = label => {
  localStorage.removeItem(label);
}
