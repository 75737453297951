import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Support from '../../shared/Support';
import useSessionEntity from '../../../hooks/useSessionEntity';

export default function ExpiredLink() {
  const merchant = useSessionEntity('merchant');

  return (
    <div id="content" role="main"  className="mx-6 sm:mx-auto">
      <div className="my-4 flex items-center">
        <span className="fa-layers fa-fw text-5xl">
          <FontAwesomeIcon
            icon="square"
            className="text-background-error !mx-0"
          />
          <FontAwesomeIcon
            icon={['far', 'circle-xmark']}
            className="text-error text-3xl !mx-1.5"
          />
        </span>
        <div className="text-xl sm:text-4xl font-extrabold">Expired Link</div>
      </div>
      <h1 className="font-semibold text-lg tracking-wide mb-3">
        This link has expired.
      </h1>
      <p className="font-light sm:text-lg tracking-wide mb-3">
        Please ask your {merchant?.name} rep to create a new Credit Key order,
        if needed.
      </p>
      <div className="mt-6 sm:mt-12">
        <Support />
      </div>
    </div>
  );
}
