import * as ActionTypes from '../lib/constants/ActionTypes';
import Rollbar from 'rollbar';
import { platform } from '../lib/constants/SiteVariables';
import { loading } from './sessions';
import { pdExpire } from '../lib/constants/PersistentData';

const debugPlatforms = ['staging', 'production'];
const env = platform();
export const rollbarConfig = new Rollbar({
  enabled: debugPlatforms.includes(env),
  accessToken: '754ff5f619ba42d89eea31fc98e5762b',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    source_map_enabled: true,
    environment: env,
    code_version: process.env.REACT_APP_GIT_SHA,
    guess_uncaught_frames: true
  }
});

export const asyncActions = (...actions) => {
  return (dispatch, getState) => {
    const rethrow = getState().general.get('rethrow');

    return actions
      .filter(a => a)
      .reduce((previousPromise, nextAsyncFunction) => {
        return previousPromise
          .then(() => dispatch(nextAsyncFunction));
      }, Promise.resolve())
      .catch(err => {
        dispatch(handleAsyncError(err));
        if (rethrow) throw (err);
      });
  };
};

export const clearErrors = () => ({
  type: ActionTypes.CLEAR_ERRORS
});

export const error = e => {
  return async (dispatch, getState) => {
    const session = getState().sessions.get('session');

    let parsedErr;

    if (session) {
      dispatch(setDebugData('key', session.public_key));
      dispatch(setDebugData('workflow', session.workflow));
      dispatch(setDebugData('action', session.action));
    }

    // if we're getting unauthorized responses, expire the local data to force reauth
    if (e.response && e.response.status === 401) {
      pdExpire();
      // otherwise check for a json or text method
    } else if (e.response) {
      parsedErr = `${e.response.statusText}: ${e.response.data.error || e.response.data.errorMessage}`;
    }

    if (parsedErr) {
      dispatch({
        type: ActionTypes.SET_ERROR,
        payload: parsedErr
      });
    }
  };
};

export const setDebugData = (key, val) => ({
  type: ActionTypes.SET_DEBUG,
  payload: {
    key: key,
    value: val
  }
});

export const handleAsyncError = err => {
  return (dispatch, getState) => {
    dispatch(error(err));
    dispatch(loading(false));
  };
};

export const toggleRethrow = () => ({
  type: ActionTypes.TOGGLE_RETHROW
});
