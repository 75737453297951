const TEXT_LOOKUP = {
  vaping: {
    yes: "<strong>is</strong> a marijuana dispensary and/or sells products containing THC.",
    no: "is <strong>not</strong> a marijuana dispensary and <strong>does not</strong> sell products containing THC."
  }
};

export const restrictedTextYes = (merchant) => merchant && merchant.restricted_industry ? TEXT_LOOKUP[merchant.restricted_industry]['yes'] : '';
export const restrictedTextNo = (merchant) => merchant && merchant.restricted_industry ? TEXT_LOOKUP[merchant.restricted_industry]['no'] : '';

export const merchantIsCreditKey = (merchant) => ['credit-key', 'learnmorenow'].includes(merchant?.slug) || merchant?.public_key.includes('creditkey');
