import { receiveEntity } from './entities';

const type = 'merchants';

const path = resource => {
  if (!resource) resource = '';
  return { resource: 'api/' + type + '/' + resource };
}

export const getMerchant = id => {
  return (dispatch, getState, api) => {
    return api.get(path(id))
      .then(res => dispatch(receiveEntity(res)));
  }
}
