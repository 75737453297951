import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';

import Error from '../../shared/Error';
import Loading from '../../shared/Loading';
import Support from '../../shared/Support';
import useRouteStatus from '../../../hooks/useRouteStatus';
import useSession from '../../../hooks/useSession';
import useSessionEntity from '../../../hooks/useSessionEntity';
import { asyncActions } from '../../../actions/general';
import { formatCurrency } from '../../../lib/utils/formatters';
import { getCreditLineAvailable } from '../../../actions/companies';
import { loading } from '../../../actions/sessions';

export default function PastDue({ location }) {
  const [session, sessionActions] = useSession();
  const dispatch = useDispatch();

  const company = useSessionEntity('company');
  const user = useSessionEntity('user');

  const [tclAvailable, setTclAvailable] = useState(0);

  useRouteStatus(location);

  useEffect(() => {
    if (company) {
      dispatch(loading(true));
      dispatch(asyncActions(getCreditLineAvailable(company.id)))
        .then((res) => setTclAvailable(res.attributes.data))
        .then((res) => dispatch(loading(false)));
    }
  }, [company, dispatch]);

  useEffect(() => {
    sessionActions.finish();
  }, []);

  let bodyText = '';
  if (company.credit_line.get('amount') > 0) {
    bodyText = (
      <>
        <p className="tracking-wide font-semibold sm:text-lg mb-3 sm:mb-6">
          Good news! You have {formatCurrency(tclAvailable)} to spend with
          Credit Key!
        </p>

        <p className="font-light sm:text-lg tracking-wide mb-6">
          Unfortunately your account is past due. In order to continue using
          your line of credit, please contact Credit Key Support to resolve this
          issue.
        </p>
      </>
    );
  } else {
    bodyText = (
      <>
        <p className="tracking-wide font-semibold sm:text-lg mb-3 sm:mb-6">
          There seems to be a problem with your Credit Key account.
        </p>

        <p className="font-light sm:text-lg tracking-wide mb-6">
          Please contact us as soon as possible.
        </p>
      </>
    );
  }

  if (!user || !company) return <Loading className="w-14 h-14" containerClass="my-24 sm:my-0" />;

  return (
    <div role="main"  className="mx-6 sm:mx-auto">
      <div className="my-4 flex items-center">
        <span className="fa-layers fa-fw text-5xl">
          <FontAwesomeIcon
            icon="square"
            className={classNames(
              '!mx-0',
              company.credit_line.get('amount') > 0
                ? 'text-background-success'
                : 'text-background-error',
            )}
          />
          <FontAwesomeIcon
            icon={
              company.credit_line.get('amount') > 0
                ? ['far', 'circle-check']
                : ['far', 'triangle-exclamation']
            }
            className={classNames(
              'text-3xl !mx-1.5',
              company.credit_line.get('amount') > 0
                ? 'text-success'
                : 'text-error',
            )}
          />
        </span>
        <div className="text-xl sm:text-4xl font-extrabold">
          Hi {user.first_name}!
        </div>
      </div>

      <Error />

      {bodyText}

      <div className="mt-6 sm:mt-12">
        <Support />
      </div>
    </div>
  );
}
