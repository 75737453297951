import React from 'react';
import { Redirect } from 'react-router-dom';

/*
 * This component shouldn't be called as part of a normal flow
 * but it is still part of session_routing on the API and thus 
 * needs a UI counterpart in case it is unexpectedly called.
 * Expected behavior is that the API places the order on the
 * merchant via the merchant's ecommerce API and a hard redirect
 * is triggered to the shopping_cart.return_url in the Confirm
 * component, halting standard session_routing
*/

export default function CallbackApi() {
  return (<Redirect to="/finish/order_confirmation" />);
}
