import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

function useValidateRoute(
  options = {
    apply: false,
    company: false,
  },
) {
  function validCompanyState(company) {
    const statuses = ['new', 'unknown'];
    return (
      !company || (company && statuses.includes(company.underwriting_status))
    );
  }

  const history = useHistory();
  const application = useSelector((state) =>
    state.entities.get('application').first(),
  );
  const company = useSelector((state) => state.entities.get('company').first());

  useEffect(() => {
    if (options.apply && !application) history.replace('/init/existing_user');
    if (options.company && !validCompanyState(company))
      history.replace('/invalid');
  }, []);

  return validCompanyState(company);
}

export default useValidateRoute;
