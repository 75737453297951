import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import Error from '../../shared/Error';
import Loading from '../../shared/Loading';
import PrimaryButton from '../../shared/buttons/PrimaryButton';
import ProgressBars from '../../shared/ProgressBars';
import UserRecord from '../../../records/user';
import useRouteStatus from '../../../hooks/useRouteStatus';
import useSessionEntity from '../../../hooks/useSessionEntity';
import {
  getRoute,
  getReferringSession,
  loading,
} from '../../../actions/sessions';
import { asyncActions } from '../../../actions/general';
import { getUser } from '../../../actions/users';
import { updateApplication } from '../../../actions/applications';

export default function AuthorizePurchaser({ location }) {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.sessions.get('loading'));
  const application = useSessionEntity('application');
  const [user, setUser] = useState(null);
  const [purchaser, isPurchaser] = useState(true);

  useRouteStatus(location);

  useEffect(() => {
    dispatch(getReferringSession())
      .then((session) => dispatch(getUser(session.depId('user'), true)))
      .then((user) => setUser(new UserRecord(user.data.attributes)));
  }, []);

  const save = () => {
    dispatch(loading(true));
    dispatch(
      asyncActions(
        updateApplication(
          application.set('has_authorized_purchaser', purchaser),
        ),
        getRoute(),
      ),
    ).then((res) => dispatch(loading(false)));
  };

  if (!user) return <Loading className="w-14 h-14" containerClass="my-24 sm:my-0" />;

  return (
    <div role="main" className="mx-6 sm:mx-auto">
      <ProgressBars />
      <div className="mt-4 mb-1 sm:mb-4">
        <div className="text-xl sm:text-4xl font-extrabold">
          Authorize {user.first_name} {user.last_name} for future purchases?
        </div>
      </div>

      <Error />

      <div className="flex flex-col items-center gap-4 w-full my-6 sm:my-10">
        <div
          className={classNames(
            'flex items-center border rounded-lg py-3 px-6 w-full group',
            purchaser ? 'border-primary-blue' : 'primary-border-gray ',
          )}
          onClick={() => isPurchaser(true)}
        >
          <input
            type="radio"
            className="h-4 w-4 sm:h-5 sm:w-5 border-tertiary-gray group-focus:ring-primary-blue mt-1"
            id="has_authorized_purchaser"
            name="has_authorized_purchaser"
            onChange={() => isPurchaser(true)}
            value={purchaser}
            checked={purchaser}
          />
          <label
            htmlFor="has_authorized_purchaser"
            className="ml-4 block text-sm sm:text-lg font-light tracking-wide"
            onClick={() => isPurchaser(true)}
          >
            I authorize {user.first_name} {user.last_name} to sign on behalf of{' '}
            {application.company_name}
          </label>
        </div>

        <div
          className={classNames(
            'flex items-center border rounded-lg py-3 px-6 w-full group',
            !purchaser ? 'border-primary-blue' : 'primary-border-gray',
          )}
          onClick={() => isPurchaser(false)}
        >
          <input
            type="radio"
            className="h-4 w-4 sm:h-5 sm:w-5 border-tertiary-gray group-focus:ring-primary-blue mt-1"
            id="is_not_authorized_purchaser"
            name="has_authorized_purchaser"
            onChange={() => isPurchaser(false)}
            value={purchaser}
            checked={!purchaser}
          />
          <label
            htmlFor="is_not_authorized_purchaser"
            className="ml-4 block text-sm sm:text-lg font-light tracking-wide"
            onClick={() => isPurchaser(false)}
          >
            I would like to approve each purchase separately
          </label>
        </div>
      </div>

      <PrimaryButton
        buttonText="Next"
        buttonColor='bg-primary-blue'
        hoverClass='hover:bg-primary-blue-hover'
        disabled={isLoading}
        isLoading={isLoading}
        onClick={save}
      />
    </div>
  );
}
