import { Record } from 'immutable';

import { convertRawValues } from '../lib/utils/record_utils';
import CreditLine from './credit_line';

export default class CompanyRecord extends Record({
  id: null,
  name: '',
  underwriting_status: 'unknown',
  bank_verification_attempts: 0,
  applicant_fico_score: '',
  short_display: '',
  tier: '',
  credit_line: new CreditLine(),
  recharge_settings: [],
  products: [],
}) {
  constructor(data) {
    data = convertRawValues(data);

    if (data.get('credit_line')) {
      data = data.set('credit_line', new CreditLine(data.get('credit_line')));
    }

    super(data);
  }

  setRechargeSettings(merchant_id, term) {
    const existingIndex = this.get('recharge_settings').findIndex((s) => {
      return s.get('merchant_id').toString() === merchant_id.toString();
    });

    return this.mergeIn(
      ['recharge_settings', existingIndex === -1 ? 1 : existingIndex],
      { default_term: term, merchant_id: merchant_id.toString() }
    );
  }
}
